import { entries, clear, setMany } from 'idb-keyval';
import Joi from 'joi'

export const setupStateStore = async () => {
    try {
        let stateStore = await entries();
        const schema = Joi.array().items(
            Joi.array().ordered(Joi.string().valid('states').required(), Joi.array().items(Joi.object()).length(5).required()),
            Joi.array().ordered(Joi.string().valid('temp-state').required(), Joi.object().required()),
            Joi.array().ordered(Joi.string().valid('showcase-state').required(), Joi.object().required())
        ).length(3);

        const { error } = schema.validate(stateStore)
        if (error) {
            await clear();
            await setMany([
                [`states`, [{}, {}, {}, {}, {}]],
                [`temp-state`, {}],
                [`showcase-state`, {}]
            ]);
        }
    } catch (err) {
        console.error(err)	
    }
}