import { TopMenu } from './Components/TopMenu'
import {
    Grid,
    Button,
    Container,
    Divider,
    Header,
    Icon,
    Image,
    Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { Emoji } from './Components/Emoji';
import { Footer } from './Components/Footer';
import { FAQModal } from './Components/FAQModal';
import { createMedia } from '@artsy/fresnel'
import './About.css';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      mobile: 0,
      tablet: 768,
      computer: 1024,
    },
  })
  
const AboutHeading = () => (
    <Container text>
        <Header
            id="h1"
            as='h1'
            inverted
            className="h1"
            content={
                <div>
                    <img height="150px" alt="Mancer Logo" src="./mancer-logo-small.webp" />
                    <div>
                        Mancer
                    </div>
                </div>
            }
        />
        <Header
            id="h2"
            as='h2'
            inverted
            content="Summon your creativity"
        />
        <Button as={Link} to="/create" style={{ marginTop: '1em' }} inverted color="yellow" size='huge'>
            Get Started
			<Icon name='right arrow' />
        </Button>
    </Container>
)


const DesktopContainer = (props) => {
    const { children } = props
    return (
    <Media greaterThan='mobile'>
        <div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '22em' }} >
            <Segment
                id="h1Segment"
                inverted
                textAlign='center'
                vertical
            >
                <Container >
                    <TopMenu />
                    <AboutHeading />
                </Container>
            </Segment>
            { children }
        </div>
    </Media>
    )
}

const MobileContainer = (props) => {
    const { children } = props
    return (
        <Media at='mobile'>
            <div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '33em' }} >
                <Segment
                    id="h1Segment"
                    inverted
                    textAlign='center'
                    vertical
                    style={{padding: 0}}
                >
                    <TopMenu mobile />
                    <AboutHeading />
                </Segment>
                { children }
            </div>
        </Media>
    )
}

const ResponsiveContainer = ({ children }) => (
    <MediaContextProvider>
      <DesktopContainer>{children}</DesktopContainer>
      <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
  )

MobileContainer.propTypes = {
    children: PropTypes.node,
  }

DesktopContainer.propTypes = {
    children: PropTypes.node
}

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}

export const AboutLayout = () => {
    return (
        <ResponsiveContainer>
            <Segment style={{ padding: '8em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as='h1' color="purple" inverted >
                                What is this? <Emoji emoji="🤔" />
                            </Header>

                            <p className="lightText" style={{ fontSize: '1.33em' }}>
                                Mancer is a free painting app that makes it fun and easy to turn, your ideas in physical objects... we call it summoning.
                            </p>

                            <Header as='h1' inverted color="purple">
                                Designed for simplicity and power. <Emoji emoji="💪" />
                            </Header>

                            <p className="lightText" style={{ fontSize: '1.33em' }}>
                                "Chaos" is what makes Mancer simple, yet powerful to use.
                                By encouraging, randomness, happy accidents, and experimentation anyone is able to create unique, eye catching designs.
                            </p>

                        </Grid.Column>
                        <Grid.Column floated='right' width={8}>
                            <Image size='huge' src="/wizard.webp" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{ padding: '0em' }} vertical>
                <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                            <Header as='h1' color="purple" inverted style={{ fontSize: '2em' }}>
                                <Emoji emoji="🧙‍♂" /> Summon Art
                            </Header>
                            <p className="lightText" style={{ fontSize: '1.33em' }}>Turn your designs into T-Shirts, and soon much more</p>
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                            <Header as='h1' color="purple" inverted style={{ fontSize: '2em' }}>
                                <Emoji emoji="🌌 " /> Showcase your Skills
                            </Header>
                            <p className="lightText" style={{ fontSize: '1.33em' }}>
                                Browse, Share, and Summon designs from the <NavLink className="yellowLink" to="/showcase">Showcase</NavLink>
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{ padding: '8em 0em' }} vertical>
                <Container text>
                    <Header as='h1' inverted color="purple" style={{ fontSize: '2em' }}>
                        <Emoji emoji="🤗" /> Sharing is Caring
                    </Header>
                    <p className="lightText" style={{ fontSize: '1.33em' }}>
                        Share your designs, and order designs shared by others.
                        All designs in the Showcase are licensed with <a className="yellowLink" href="https://creativecommons.org/share-your-work/public-domain/cc0/" target="_blank" rel="noreferrer">Creative Commons CC0 license </a>
                        <Image inline src="./88x31.png" />
                    </p>
                    <Divider
                        style={{ margin: '3em 0em' }}
                    />
                    <Header as='h1' inverted color="purple" style={{ fontSize: '2em' }}>
                        <Emoji emoji="🙋" /> Any Questions?
                    </Header>
                    <p className="lightText" style={{ fontSize: '1.33em' }}>
                        Check out the <FAQModal/> or come hangout on the <a className="yellowLink" target="_blank" rel="noreferrer" href="https://discord.gg/UzTekKMtY2">Mancer Discord</a> server                        
                    </p>
                </Container>
            </Segment>
            <Footer />
        </ResponsiveContainer>
    )
}
