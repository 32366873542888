import { useEffect, useRef, useState } from 'react';
import { PaintSystem } from './Util/PaintSystem';
import { Menu, Icon, Sidebar, Ref, Popup, Segment} from 'semantic-ui-react';
import { ToolMenu } from './Components/ToolMenu';
import { CanvasMenu } from './Components/CanvasMenu';
import { TopMenu } from './Components/TopMenu';
import { paintSysState } from './Util/createInitalState.js';
import { useHotkeys } from 'react-hotkeys-hook';
import { createMedia } from '@artsy/fresnel'
import './Create.css';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      mobile: 0,
      tablet: 768,
      computer: 1024,
    },
  })

const PaintSys = new PaintSystem(paintSysState); // Pass by reference

const CreateLayout = () => {
	const [pState, setPState] = useState(paintSysState);
	const [changed, setChange] = useState(null);
	const ref = useRef(null);
	const [visible, setVisible] = useState(false)
	const [tool, setTool] = useState('shapesBrush');
	const sideBarToggleRef = useRef();
	
	useHotkeys('e', () => handleTool('emojiBrush'));
	useHotkeys('a', () => handleTool('eraserBrush')); 
	useHotkeys('s', () => handleTool('shapesBrush')); 
	useHotkeys('t', () => handleTool('textBrush'));
	useHotkeys('f', () => handleTool('fill'));
	useHotkeys('h', () => handleTool('hand'));
	useHotkeys('z', () => handleTool('zoomIn')); 
	useHotkeys('x', () => handleTool('zoomOut'));
	useHotkeys('c', () => resetView());
	useHotkeys('r', () => rotate45());
	useHotkeys('q', () => {setVisible(visible => !visible)});
	useHotkeys('ctrl+d', () => setChange('spin'));
	useHotkeys('ctrl+e', () => setChange('export'));
	useHotkeys('ctrl+z', () => undo());
	useHotkeys('ctrl+x', () => redo());

	const handleChange = (change) => {
		let options = {
			'save': () => PaintSys.saveDocument(pState.document),
			'saveTemp': () => PaintSys.saveDocument(pState.document, true),
			'new': () => PaintSys.newDocument(pState.document),
			'open': () => PaintSys.openDocument(pState.document.stateId),
			'tshirtColor': () => PaintSys.changeTshirtColor(pState.document.templateColor),
			'export': () => PaintSys.exportDocument(pState.document.title),
			'spin': () => PaintSys.spinDocument(),
			'default': () => null,
		}
		if (change) {
			(options[change] || options['default'])();
			setChange(null);
		}
	}

	useEffect(() => { // run once
		document.body.style.overflow = "hidden";
		ref.current.appendChild(PaintSys.app.view);
		
		if (PaintSys.doc.maxTextureSize === 4096 ) {
			PaintSys.app.start();
		} else if (PaintSys.doc.maxTextureSize > 4096) {
			PaintSys.app.start();
		} else if (PaintSys.doc.maxTextureSize < 4096) {
			alert('Error: WebGL max texture size less than 4096. Your device / browser is unsupported, please try using another web browser or device.')
			PaintSys.app.stop();
		}

		return () => { 
			document.body.style.overflow = "auto";
			PaintSys.app.stop();
		}
	}, []);

	useEffect(() => { // run on every data change
		handleChange(changed);
	});

	const handleTool = (tool) => {
		paintSysState.tools.selected = tool
		PaintSys.changeMouse(tool)
		setTool(tool);
	}

	const rotate45 = () => { PaintSys.rotate45() }
	const undo = () => { PaintSys.undo() }
	const redo = () => { PaintSys.redo() }
	const resetView = () => { PaintSys.resetView() }

	return (
		<div>
			<Segment
				id="topMenuSegment"
                inverted
                textAlign='center'
                vertical
            >
				<MediaContextProvider>
					<Media greaterThan="mobile">
						<TopMenu />
					</Media>
					<Media at="mobile">
						<TopMenu mobile />
					</Media>
				</MediaContextProvider>
            </Segment>

			<Sidebar.Pushable style={{
				position: 'fixed',
				zIndex: 1,
				width: '28em',
				pointerEvents: visible ? 'auto' : 'none'
			}}>
				<Sidebar
					as={Menu}
					animation='push'
					target={sideBarToggleRef}
					inverted
					borderless
					onHide={() => setVisible(false)}
					vertical
					visible={visible}
					width='wide'
					icon
					className="hideScroll"
				>
					<CanvasMenu mobile={PaintSys.mobile} tool={tool} data={pState.document} setData={e => setPState(e)} setChange={e => setChange(e)} paintSys={PaintSys} />
					<ToolMenu tool={tool} data={pState.tools} setData={e => setPState(e)} setChange={e => setChange(e)} />
				</Sidebar>

				<Sidebar.Pusher>
					<Menu
						style={{
							pointerEvents: 'auto',
							position: 'absolute',
							top: '50%',
							height: '520px',
							marginTop: '-260px',
							borderRadius: '0px 14px 14px 0px',
						}}
						vertical inverted icon>

						<Popup
							trigger={
								<Menu.Item onClick={() => setVisible(!visible)}>
									<Ref innerRef={sideBarToggleRef} >
										<div style={{ width: '100%', height: '100%' }} />
									</Ref>
									<Icon name='bars' onClick={() => setVisible(!visible)} />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Settings (Q)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='shapesBrush'
									onClick={(e) => handleTool(e.target.id)}
									color='purple'
									active={tool === 'shapesBrush'}
								>
									<Icon id='shapesBrush' name='paint brush' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Shape Brush (S)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='textBrush'
									onClick={(e) => handleTool(e.target.id)}
									color='olive'
									active={tool === 'textBrush'}
								>
									<Icon id='textBrush' name='font' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Text Brush (T)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='emojiBrush'
									onClick={(e) => handleTool(e.target.id)}
									color='orange'
									active={tool === 'emojiBrush'}
								>
									<Icon id='emojiBrush' name='smile' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Emoji Brush (E)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id="eraserBrush"
									onClick={(e) => handleTool(e.target.id)}
									color='pink'
									active={tool === 'eraserBrush'}
								>
									<Icon id='eraserBrush' name='eraser' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Eraser (A)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id="fill"
									onClick={(e) => handleTool(e.target.id)}
									color='violet'
									active={tool === 'fill'}
								>
									<Icon id='fill' name='tint' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Fill (F)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='zoomIn'
									active={tool === 'zoomIn'}
									color='green'
									onClick={(e) => handleTool(e.target.id)}
								>
									<Icon id='zoomIn' name='search plus' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Zoom In (Z)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='zoomOut'
									active={tool === 'zoomOut'}
									color='red'
									onClick={(e) => handleTool(e.target.id)}
								>
									<Icon id='zoomOut' name='search minus' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Zoom Out (X)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='hand'
									onClick={(e) => handleTool(e.target.id)}
									color='teal'
									active={tool === 'hand'}
								>
									<Icon id='hand' name='hand paper' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Move Tool (H)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='rotate'
									onClick={() => rotate45()}
								>
									<Icon id='rotate' name='sync' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Rotate 45 (R)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='center'
									onClick={() => resetView()}
								>
									<Icon id='center' name='crosshairs' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Center View (C)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>

						<Popup
							trigger={
								<Menu.Item
									id='undo'
									onClick={() => undo()}
								>
									<Icon id='undo' name='undo' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Undo (CTRL + Z)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>
						<Popup
							trigger={
								<Menu.Item
									id='redo'
									onClick={() => redo()}
								>
									<Icon id='redo' name='redo' />
								</Menu.Item>
							}
							disabled={PaintSys.mobile}
							content="Redo (CTRL + X)"
							inverted
							mouseEnterDelay={500}
							size='tiny'
							position='right center'
						/>
					</Menu>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
			<div ref={ref} className="paintCanvas" />
		</div>
	)
}

export default CreateLayout