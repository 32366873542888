import {Segment, Container, Grid, List, Header} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './Footer.css'

export const Footer = () => (
    <Segment inverted vertical className="footer" >
        <Container>
            <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content='Sitemap' />
                        <List link inverted>
                            <List.Item as={Link} to='/'>About</List.Item>
                            <List.Item as={Link} to='/create'>Create</List.Item>
                            <List.Item as={Link} to='/showcase'>Showcase</List.Item>
                            <List.Item as={Link} to='/summon'>Summon</List.Item>
                            <List.Item as={Link} to='/cart'>Cart</List.Item>
                            <List.Item as="a" href="mailto:josh@joshuasheridan.com">Contact</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Header as='h4' inverted>
                            Boring Stuff
                        </Header>
                        <p>
                        Created by: <a style={{color: "#FFE21F"}} href="https://joshuasheridan.com">Joshua Sheridan</a> <br />
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>
)

