import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { CartProvider } from 'use-shopping-cart'
import { loadStripe } from '@stripe/stripe-js'
import 'semantic-ui-less/semantic.less'

const stripePromise = loadStripe('pk_live_51IL9PnL70vp0tCeMbjrv8cCgTDhRCyVB1z5bYNLmBFNijyRWaIsXm2JgtoAkIcTAebhxVZBeqDLp6EFMaYMwikTG00ZA4sLSMR')

ReactDOM.render(
  <React.StrictMode>
    <CartProvider
      mode="checkout-session"
      stripe={stripePromise}
      currency="USD"
    >
        <App />
    </CartProvider>
  </React.StrictMode>,
  document.getElementById('root')
);