import Gui, { GuiString, GuiBool, GuiColor, GuiSelect, GuiNumber } from '../../react-gui-controller';
import { Menu, Header } from 'semantic-ui-react';
import './ToolMenu.css'

export const ToolMenu = (props) => {
    const guiOpt = {
        shapesBrush: () => (
            <Gui
                data={props.data.shapesBrush}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                { [ <GuiSelect key={0} path="shape" label="Shape" options={['circle', 'ellipse', 'triangle', 'line', 'star', 'enneagram', 'square', 'roundedsquare']} /> ] }
            </Gui>
        ),
        textBrush: () => (
            <Gui
                data={props.data.textBrush.textOptions}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                <GuiSelect path="fontFamily" label="Font Family" options={["Arial", "Verdana", "Helvetica", "Tahoma", "Trebuchet MS", "Times New Roman", "Georgia", "Garamond", "Courier New", "Brush Script MT"]} />
                <GuiBool path="fontBold" label="Bold" />
                <GuiBool path="fontItalics" label="Italics" />
                <GuiBool path="chaosText" label="Chaos Words" />
                { !props.data.textBrush.textOptions.chaosText && <GuiString path="staticText" label="Text" />}
                { props.data.textBrush.textOptions.chaosText && <GuiNumber path="chaosTextMaxWordLength" label="Max Word Length" min={2} max={14} step={1} />}
            </Gui>
        ),
        emojiBrush: () => (
            <Gui
                data={props.data.emojiBrush.emojiOptions}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                { [ <GuiSelect key={0} path="chaosEmojiLibrary" label="Catagory" options={["all", "animals", "flags", "food", "misc", "nature", "objects", "people", "smilies", "symbols", "travel"]} /> ] }
            </Gui>
        ),
        fill: () => (
            <Gui
                data={props.data.fill}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                <GuiColor path="staticColor" label="Color" type="hexNum" />
                <GuiNumber path="alpha" label="Alpha" min={0} max={1} step={0.01} />
            </Gui>
        ),
        eraserBrush: () => (
            <Gui
                data={props.data.eraserBrush}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                { [ <GuiSelect key={0} path="shape" label="Shape" options={['circle', 'ellipse', 'triangle', 'line', 'star', 'enneagram', 'square', 'roundedsquare']} /> ] }
            </Gui>
        ),
        common: (toolSel) => (
            <Gui
                data={props.data[toolSel]}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                <GuiNumber path="size" label="Size" min={1} max={500} step={1} />
                <GuiNumber path="step" label="Step" min={0.01} max={2} step={0.01} />
                {toolSel !== "emojiBrush" && <GuiBool path="outline" label="Outline" />}
                <GuiBool path='rotation' label="Rotation" />
            </Gui>
        ),
        color: (toolSel) => (
            <Gui
                data={props.data[toolSel].color}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                <GuiNumber path="alpha" label="Alpha" min={0} max={1} step={0.01} />
                <GuiSelect path="blendMode" label="Blend Mode" options={['normal', 'dodge', 'burn', 'screen']} />
                <GuiBool path='chaosColor' label="Chaos Color" />
                { !props.data[toolSel].color.chaosColor && <GuiColor path="staticColor" label="Color" type="hexNum" />}
                { props.data[toolSel].color.chaosColor && <GuiSelect path="chaosMode" label="Mode" options={['crazy', 'groups']} />}
                { props.data[toolSel].color.chaosColor && <GuiSelect path="chaosColorLibrary" label="Palette" options={['random', 'pastels', 'neons', 'greys', 'muted', 'reds', 'vermilions','oranges','yellows','chartreuses','greens','teals','blues','indigos','violets','purples','magentas']} />}
            </Gui>
        ),
        colorEraser: (toolSel) => (
            <Gui
                data={props.data[toolSel].color}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                { [ <GuiNumber key={0} path="alpha" label="Alpha" min={0} max={1} step={0.01} /> ] }
            </Gui>
        ),
        motion: (toolSel) => (
            <Gui
                data={props.data[toolSel].motion}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                <GuiBool path='chaosMotion' label="Chaos Motion" />
                { props.data[toolSel].motion.chaosMotion && <GuiSelect path="chaosMode" label="Mode" options={['out', 'in', 'spots', 'spiral', 'down']} />}
                { props.data[toolSel].motion.chaosMotion && <GuiNumber path="friction" label="Friction" min={0.1} max={10} step={0.1} />}
                { props.data[toolSel].motion.chaosMotion && <GuiSelect path="dynamics" label="Dynamics" options={['none', 'scatter', 'multi']} />}
            </Gui>
        ),
        effects: (toolSel) => (
            <Gui
                data={props.data[toolSel].effects}
                onUpdate={() => props.setData(data => ({ ...data }))}
                onChange={e => props.setChange(e)}
                theme="dark">
                <GuiSelect path="filter1" label="Filter 1" options={['none', 'ascii', 'dot', 'twist', 'bevel', 'outline', 'pixelate', 'dropshadow', 'glow', 'kawaseblur', 'noise']} />
                <GuiSelect path="filter2" label="Filter 2" options={['none', 'ascii', 'dot', 'twist', 'bevel', 'outline', 'pixelate', 'dropshadow', 'glow', 'kawaseblur', 'noise']} />
            </Gui>
        )
    }

    return (
        <div>
            {
                props.tool === "shapesBrush" &&
                    <>
                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='purple'>Common</Header>
                            <Menu.Menu>
                                {guiOpt.common(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color="purple">Brush</Header>
                            <Menu.Menu>
                                {guiOpt.shapesBrush()}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='purple'>Color</Header>
                            <Menu.Menu>
                                {guiOpt.color(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='purple'>Motion</Header>
                            <Menu.Menu>
                                {guiOpt.motion(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItemBot">
                            <Header as="h5" inverted color='purple'>Effects</Header>
                            <Menu.Menu>
                                {guiOpt.effects(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>
                    </>
            }

            {
                props.tool === "textBrush" &&
                    <>
                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='olive'>Common</Header>
                            <Menu.Menu>
                                {guiOpt.common(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color="olive">Text</Header>
                            <Menu.Menu>
                                {guiOpt.textBrush()}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='olive'>Color</Header>
                            <Menu.Menu>
                                {guiOpt.color(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='olive'>Motion</Header>
                            <Menu.Menu>
                                {guiOpt.motion(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItemBot">
                            <Header as="h5" inverted color='olive'>Effects</Header>
                            <Menu.Menu>
                                {guiOpt.effects(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>
                    </>
            }

            {
                props.tool === "emojiBrush" &&
                    <>
                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='yellow'>Common</Header>
                            <Menu.Menu>
                                {guiOpt.common(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color="yellow">Emoji</Header>
                            <Menu.Menu>
                                {guiOpt.emojiBrush()}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='yellow'>Color</Header>
                            <Menu.Menu>
                                {guiOpt.color(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='yellow'>Motion</Header>
                            <Menu.Menu>
                                {guiOpt.motion(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItemBot">
                            <Header as="h5" inverted color='yellow'>Effects</Header>
                            <Menu.Menu>
                                {guiOpt.effects(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>
                    </>
            }

            {
                props.tool === "fill" &&
                    <>
                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color="violet">Fill</Header>
                            <Menu.Menu>
                                {guiOpt.fill()}
                            </Menu.Menu>
                        </Menu.Item>
                    </>
            }

            {
                props.tool === "eraserBrush" &&
                    <>
                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='pink'>Common</Header>
                            <Menu.Menu>
                                {guiOpt.common(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color="pink">Eraser</Header>
                            <Menu.Menu>
                                {guiOpt.eraserBrush()}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='pink'>Color</Header>
                            <Menu.Menu>
                                {guiOpt.colorEraser(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>

                        <Menu.Item className="menuItem">
                            <Header as="h5" inverted color='pink'>Motion</Header>
                            <Menu.Menu>
                                {guiOpt.motion(props.tool)}
                            </Menu.Menu>
                        </Menu.Item>
                    </>
            }
        </div>
    )
}

export default ToolMenu;