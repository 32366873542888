import { TopMenu } from './Components/TopMenu';
import { Container, Header, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Footer } from './Components/Footer';
import { Gallery } from './Components/Gallery';
import { createMedia } from '@artsy/fresnel'

const { MediaContextProvider, Media } = createMedia({
	breakpoints: {
		mobile: 0,
		tablet: 768,
		computer: 1024,
	},
})

const ShowcaseHeading = () => (
	<Container text>
		<Header as='h1'
			inverted
			content="🖼  Showcase"
			style={{
				marginTop: '1.5em'
			}}
		/>
	</Container>
)

const DesktopContainer = (props) => {
	const { children } = props
	return (
		<Media greaterThan='mobile'>
			<div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '22em' }} >
				<Segment
					inverted
					textAlign='center'
					style={{ minHeight: 200, padding: '1em 0em', borderStyle: "none none solid none", borderColor: "#c7158580" }}
					vertical
				>
					<Container>
						<TopMenu />
						<ShowcaseHeading />
					</Container>
				</Segment>
				{children}
			</div>
		</Media>
	)
}

const MobileContainer = (props) => {
	const { children } = props
	return (
		<Media at='mobile'>
			<div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '38em' }} >
				<Segment
					inverted
					textAlign='center'
					style={{ minHeight: 200, padding: 0, borderStyle: "none none solid none", borderColor: "#c7158580" }}
					vertical
				>
					<TopMenu mobile />
					<ShowcaseHeading />
				</Segment>
				{ children}
			</div>
		</Media>
	)
}

const ResponsiveContainer = ({ children }) => (
	<MediaContextProvider>
		<DesktopContainer>{children}</DesktopContainer>
		<MobileContainer>{children}</MobileContainer>
	</MediaContextProvider>
)

MobileContainer.propTypes = {
	children: PropTypes.node,
}

DesktopContainer.propTypes = {
	children: PropTypes.node
}

ResponsiveContainer.propTypes = {
	children: PropTypes.node,
}

export const ShowcaseLayout = () => (
	<ResponsiveContainer>
		<Gallery />
		<Footer />
	</ResponsiveContainer>
)