import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import Joi from 'joi'

export const RenameModal = (props) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(props.data.title);
  const [titleError, setTitleError] = useState(null);

  useEffect( () => {
    setTitle(props.data.title);
  },[props.data.title])

  const handleTitleChange = (title) => {
    const titleSchema = Joi.string().trim().min(3).max(30).regex(/^\w+(?:\s+\w+)*$/);
    const { error } = titleSchema.validate(title)
    if (error) {
      setTitleError('Only letters, spaces, numbers, and between 3 - 30 characters')
    } else {
      setTitleError(null)
    }
    setTitle(title);
  }

  const handleFormSubmit = () => {
    props.data.title = title;
    props.handleData(props.data)
    setOpen(false)
  }

  const RenamePanel = (
    <Form
      size='small'
      style={{ padding: '2em' }}
    >
      <Form.Field>
        <label>Title</label>
        <Form.Input
          value={title}
          error={titleError}
          onChange={(e) => handleTitleChange(e.target.value)}
        />
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Button
          content="Rename"
          color="green"
          fluid
          onClick={handleFormSubmit}
        />
        <Form.Button
          content="Cancel"
          color="red"
          fluid
          onClick={() => setOpen(false)}
        />
      </Form.Group>
    </Form>
  )

  return (
    <Modal
      trigger={
        <Button 
        as='div' 
        labelPosition='left' 
        fluid size="small"
        onClick={handleFormSubmit}
        >
            <Label color='blue' pointing='right'>
                Title
            </Label>
            <Button  color='blue' fluid compact>
              {props.data.title}
            </Button>
        </Button>
      }
      header='Rename?'
      content={RenamePanel}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      closeIcon
    />
  )
};