import {
	HashRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import { AboutLayout } from './Containers/About';
import CreateLayout from './Containers/Create';
import { ShowcaseLayout } from './Containers/Showcase';
import { SummonLayout } from './Containers/Summon';
import { CartLayout } from './Containers/Cart';
import { SuccessLayout } from './Containers/Success';
import { useEffect } from 'react';
import { setupStateStore } from './Containers/Util/stateStore'

const App = () => {
	useEffect( () => {
		setupStateStore();
	},[]);

	return (
		<Router>
			<Switch>
				<Route path="/success">
					<SuccessLayout/>
				</Route>
				<Route path="/cart">
					<CartLayout />
				</Route>
				<Route path="/summon">
					<SummonLayout />
				</Route>
				<Route path="/showcase">
					<ShowcaseLayout/>
				</Route>
				<Route path="/create">
					<CreateLayout/>
				</Route>
				<Route path="/">
					<AboutLayout />
				</Route>
			</Switch>
		</Router>
	)
}

export default App