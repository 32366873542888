import { useShoppingCart } from 'use-shopping-cart';
import { formatCurrencyString } from 'use-shopping-cart/src/util';
import { Button, Table, Container, Icon, TransitionablePortal, Dimmer, Message } from 'semantic-ui-react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { createMedia } from '@artsy/fresnel'
import axios from 'axios';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export const Checkout = () => {
    const [error, setError] = useState(false);
    const { totalPrice, redirectToCheckout, cartCount, removeItem, clearCart, cartDetails } = useShoppingCart();
    const tableData = Object.entries(cartDetails)
    const formatedPrice = formatCurrencyString({ value: totalPrice, currency: 'USD' })

    const removeItemFromCart = (id) => (
        <Table.Cell key={id}>
            <Button key={id} inverted fluid color="red" icon="delete" onClick={() => removeItem(id)} />
        </Table.Cell>
    )
    const headerRow = ["Item", "Size", "Color", "Amount", "Price", "Remove"]
    const renderBodyRow = (item) => ({
        key: item[0],
        cells: [
            item[1].name,
            item[1].sizeText,
            item[1].colorText,
            item[1].quantity,
            item[1].formattedValue,
            removeItemFromCart(item[1].id)
        ]
    });

    const checkoutCart = async () => {
        const url = './.netlify/functions/create-order'
        try {
            const requestPost = await axios.post(url, cartDetails);
            redirectToCheckout({ sessionId: requestPost.data.sessionId });
        } catch (err) {
            setError(true);
            console.error("an error happened")
        }
    }

    return (
        <>
        <TransitionablePortal onClose={() => setError(false)} open={error}>
            <Dimmer active={error}>
                <Message 
                icon="warning circle" 
                header="Checkout Failed" 
                error 
                hidden={!error}
                onDismiss={() => setError(false)}
                content="Please close this message and try again"
                />
			</Dimmer>
        </TransitionablePortal>
            { cartCount === 0 && <h2 style={{ margin: "3em", color: "white" }}> (ಠ_ಠ) - Your cart is empty --- To add items, goto <NavLink style={{ color: "#FFE21F" }} to="/summon">Summon</NavLink> </h2>}
            { cartCount !== 0 &&
                <MediaContextProvider>
                    <Media greaterThan='mobile'>
                        <Container style={{ marginTop: '2em', marginBottom: '2em' }}>
                            <Table
                                inverted
                                celled
                                unstackable
                                headerRow={headerRow}
                                renderBodyRow={renderBodyRow}
                                tableData={tableData}
                            />
                            <h3 style={{ color: "white", margin: 0}}> Shipping: Free</h3>
                            <h3 style={{ color: "white", marginTop: '0.5rem' }}> Total: {formatedPrice}</h3>
                            <Button inverted size="large" icon labelPosition='right' color="green" onClick={checkoutCart}> Checkout <Icon name='right arrow' /> </Button>
                            <Button inverted size="large" icon labelPosition='right' floated="right" color="red" onClick={clearCart}> Clear Cart <Icon name="delete"/> </Button>
                        </Container>
                    </Media>

                    <Media at="mobile">
                        <Table
                                inverted
                                unstackable
                                compact
                                size="small"
                                headerRow={headerRow}
                                renderBodyRow={renderBodyRow}
                                tableData={tableData}
                                style={{marginTop: '2em'}}
                            />
                            <h3 style={{ color: "white", margin: 0 }}> Shipping: Free</h3>
                            <h3 style={{ color: "white", marginTop: '0.5rem' }}> Total: {formatedPrice}</h3>
                            <Button inverted size="large" icon labelPosition='right' color="green" onClick={checkoutCart}> Checkout <Icon name='right arrow' /> </Button>
                            <Button inverted size="large" icon labelPosition='right' floated="right" color="red" onClick={clearCart}> Clear Cart <Icon name="delete"/> </Button>
                    </Media>
                </MediaContextProvider>
            }
        </>
    )
}