import { Button, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { set } from 'idb-keyval';
const url = "https://mancer-upload-public.s3.us-east-2.amazonaws.com/"

export const ShowcaseCard = (value, index) => {
    let img, author, title, template, uuid = ""

    const parseValue = (value) => {
        let pass1 = value.Key.split('/')
        let pass2 = pass1[1].split('-')

        img = url + value.Key
        author = pass2[5]
        title = pass2[6]
        template = pass2[7]
        uuid = pass2[0] + "-" + pass2[1] + "-" + pass2[2] + "-" + pass2[3] + "-" + pass2[4]
    }

    parseValue(value);

    const setShowcaseState = () => {
        set('showcase-state', {
            author: author,
            uuid: uuid,
            img: img,
            doc: {
                title: title,
                template: template,
            }
        })
    }
    
    return {
        key: index,
        raised: true,
        image: <Image src={img}/>,
        header: `"${title}"`,
        meta: `${template} design by ${author}`,
        extra: <Button id={uuid} as={Link} to="/summon" content="Summon" fluid color="green" onClick={setShowcaseState}/>
    }
}
