import { Card, TransitionablePortal, Segment, Header, Icon, Divider, Container } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { get, set } from 'idb-keyval';
import { SummonCard } from './SummonCard';

export const SummonCardGroup = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cards, setCards] = useState([]);
  const [showcaseCards, setshowcaseCards] = useState([]);
  const [openPortal, setOpenPortal] = useState(false);

  const handleClose = () => { setOpenPortal(false) }
  const handleOpen = () => {
     setOpenPortal(true)
      setTimeout(() => {
        setOpenPortal(false)
      }, 1400);
    }

  useEffect(() => {
    get("states")
      .then(
        (res) => {
          setIsLoaded(true);
          if (res) {
            let newArr = res.reduce((acc, v, i) => {
              if (!(v && Object.keys(v).length === 0 && v.constructor === Object)) { // not a blank obj
                if (v.doc.template !== "custom") {
                  acc.push(<SummonCard key={i} num={i} showcase={false} author="unknown" uploaded={v.uploaded} title={v.doc.title} price="$20" template={v.doc.template} img={v.img} uuid={v.uuid} openPortal={handleOpen} />)
                }
              }
              return acc;
            }, []);
            setCards(newArr);
          }
        },
        (err) => {
          setIsLoaded(false);
          setError("Could not load designs");
        }
      )
    get("showcase-state")
      .then(
        res => {
          if (!(res && Object.keys(res).length === 0 && res.constructor === Object)) { // not a blank obj
            setshowcaseCards([<SummonCard key={1} showcase={true} author={res.author} uploaded={true} title={`${res.doc.title}`} price="$20" template={res.doc.template} img={res.img} uuid={res.uuid} openPortal={handleOpen} />])
          }
        }
      )
      .catch(() => { 
        set('showcase-state', {})
        console.error("error loading showcase card") 
      });
  }, []);

  if (error) {
    return (
      <Container style={{marginTop: '1em'}} textAlign="center">
        <Header inverted as="h2"> Error: {error}</Header>;
      </Container>
    )
  } else if (!isLoaded) {
    return (
      <Container style={{marginTop: '1em'}} textAlign="center">
        <Header inverted as="h2"> Loading...</Header>;
      </Container>
    ) 
  } else {
    return (
      <>
        <TransitionablePortal onClose={handleClose} open={openPortal}>
          <Segment
            style={{ left: '40%', position: 'fixed', top: '20%', zIndex: 1000 }}
            inverted
            color="green"
            raised
          >
            <Header> <Icon name='cart plus' /> Item Added to Cart </Header>
          </Segment>
        </TransitionablePortal>
          <Container>
            <Header style={{paddingTop: '2em'}} inverted> <Icon name="picture" /> Design from the Showcase:</Header>
            { showcaseCards.length > 0 ?
              <>
                <Header size="small" style={{marginTop: 0}} disabled inverted >To switch the design, summon a different design from the Showcase</Header>
                <Card.Group style={{ paddingTop: '2em', paddingBottom: '2em' }} centered children={showcaseCards} />  
              </>
              :
              <Header size="small" disabled inverted> No designs summoned from the Showcase</Header>
            }
            <Divider/>
            <Header inverted> <Icon name="save" /> Your Saved Designs:</Header>
            { cards.length > 0 ? 
              <>
                <Header size="small" style={{marginTop: 0}} disabled inverted >To add designs, save a design from Create</Header>
                <Card.Group style={{paddingBottom: '2em'}} centered children={cards} />
              </>
              :
              <Header size="small" disabled inverted> (｡•́︿•̀｡) You have not saved any designs, goto Create and save a design.</Header>
            }
            
          </Container>
      </>
    )
  }
}