import { Button, Modal, Popup, Form } from 'semantic-ui-react';
import { useState } from 'react';
import tshirtProduct from '../../assets/product-tshirt.json';
import './NewModal.css'
import randomWords from 'random-words'
import Joi from 'joi'

const TemplateOptions = [
  {
    key: "tshirt",
    text: "T-Shirt",
    value: "tshirt"
  },
  {
    key: "custom",
    text: "Image Only - Cannot Summon ",
    value: "custom"
  },
]

export const NewModal = (props) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(randomWords({ exactly: 2, join: ' ', formatter: (word) => word.slice(0, 1).toUpperCase().concat(word.slice(1)) }));
  const [template, setTemplate] = useState('tshirt');
  const [TShirtColor, setTShirtColor] = useState('#ffffff');
  const [TShirtColorText, setTShirtColorText] = useState("White");
  const [width, setWidth] = useState('2000');
  const [height, setHeight] = useState('1000');
  const [widthError, setWidthError] = useState(null);
  const [heightError, setHeightError] = useState(null);
  const [titleError, setTitleError] = useState(null);

  const minSize = 64;
  const maxSize = 4096;

  const handleTitleChange = (title) => {
    const titleSchema = Joi.string().trim().min(3).max(30).regex(/^\w+(?:\s+\w+)*$/);
    const { error } = titleSchema.validate(title)
    if (error) {
      setTitleError('Only letters, spaces, numbers, and between 3 - 30 characters')
    } else {
      setTitleError(null)
    }
    setTitle(title);
  }

  const handleTemplateChange = (e, template) => {
    setTemplate(template.value)
  }
  const handleTShirtColorChange = (e, color) => {
    setTShirtColor(color.value);
    setTShirtColorText(e.target.innerText)
  }

  const handleWidthChange = (e, width) => {
    if (width.value < minSize) {
      setWidthError(`Width must be greater than: ${minSize}`);
    } else if (width.value > maxSize) {
      setWidthError(`Width must be less than: ${maxSize}`);
    } else { setWidthError(null); }
    setWidth(Math.floor(width.value))
  }

  const handleHeightChange = (e, height) => {
    if (height.value < minSize) {
      setHeightError(`Height must be greater than: ${minSize}`);
    } else if (height.value > maxSize) {
      setHeightError(`Height must be less than: ${maxSize}`);
    } else { setHeightError(null); }
    setHeight(Math.floor(height.value))
  }

  const intOnly = (e) => {
    if (e.key === 'e') { e.preventDefault() }
    else if (e.key === '.') { e.preventDefault() }
  }

  const handleFormSubmit = () => {
    props.data.title = title;
    props.data.template = template;
    props.data.stateId = 0;

    if (template === 'custom') {
      props.data.width = parseInt(width);
      props.data.height = parseInt(height);
    } else {
      props.data.templateColor = TShirtColor;
      props.data.templateColorText = TShirtColorText;
    }

    props.handleData(props.data)
    props.setChange('new')
    setOpen(false)
  }

  const NewFilePanel = (
    <Form
      size='small'
      className="formStyle"
    >
      <Form.Field>
        <label>Title</label>
        <Form.Input
          value={title}
          error={titleError}
          onChange={(e) => handleTitleChange(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Template</label>
        <Form.Dropdown
          placeholder="Select Product Template"
          fluid
          selection
          onChange={(e, template) => handleTemplateChange(e, template)}
          options={TemplateOptions}
          value={template}
        />
      </Form.Field>
      {
        template === "tshirt" &&
          <Form.Field>
            <label>T-Shirt Template Color</label>
            <Form.Dropdown
              fluid
              selection
              onChange={(e, template) => handleTShirtColorChange(e, template)}
              options={tshirtProduct.colors}
              value={TShirtColor}
            />
          </Form.Field>
      }
      { template === "custom" &&
        <Form.Group inline>
          <Form.Field>
            <label>Width</label>
            <Form.Input
              error={widthError}
              type='number'
              min={minSize}
              max={maxSize}
              onKeyDown={(e) => intOnly(e)}
              onChange={(e, width) => handleWidthChange(e, width)}
              value={width}
            />
          </Form.Field>
          <Form.Field>
            <label>Height</label>
            <Form.Input
              error={heightError}
              type='number'
              min={minSize}
              max={maxSize}
              onKeyDown={(e) => intOnly(e)}
              onChange={(e, height) => handleHeightChange(e, height)}
              value={height}
            />
          </Form.Field>
        </Form.Group>
      }
      <Form.Group widths="equal">
        <Form.Button
          content="Create"
          color="green"
          fluid
          onClick={handleFormSubmit}
        />
        <Form.Button
          content="Cancel"
          color="red"
          fluid
          onClick={() => setOpen(false)}
        />
      </Form.Group>
    </Form>
  )

  return (
    <Modal
      trigger={
        <Popup
          trigger={
            <Button
              id="newBtn"
              icon='file'
              inverted
              compact
              onClick={() => setOpen(true)}
              color='green'
            />
          }
          disabled={props.mobile}
          content="New..."
          inverted
          mouseEnterDelay={500}
          size='tiny'
          position='bottom left'
          className="popUp"
        />
      }
      header='New Design?'
      content={NewFilePanel}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      closeIcon
    />
  )
};