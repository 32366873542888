import { Dropdown } from 'semantic-ui-react';
import tshirtProduct from '../../assets/product-tshirt.json';
import './TshirtColorSelect.css';

export const TshirtColorSelect = (props) => {
      const handleTshirtColorChange = (e, template) => {
            props.data.templateColor = template.value
            props.handleData(props.data)
            props.setChange('tshirtColor')
      }

      return (
        <Dropdown
          id="TshirtColorSelect"
          text="T-Shirt Color"
          selection
          onChange={(e, template) => handleTshirtColorChange(e, template)}
          options={tshirtProduct.colors}
          value={props.data.templateColor}
        />
      )
}