import { Button, Modal, Popup, Card, Image } from 'semantic-ui-react';
import { useShoppingCart } from 'use-shopping-cart';
import { useState, useEffect } from 'react';
import { set, get } from 'idb-keyval';
import './SaveModal.css';

export const SaveModal = (props) => {
  const { clearCart } = useShoppingCart();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false)
  const [cards, setCards] = useState([]);

  useEffect(() => {
    get("states")
      .then(
        (res) => {
          setIsLoaded(true);
          let newArr = res.map((v,i) => {
            if (v && Object.keys(v).length === 0 && v.constructor === Object) { // blank obj
              return saveSlotCard(i);
            } else {
              return designCard(i, v.doc.title, v.doc.template, v.img, v.modified);
            }
          });
          setCards(newArr);
        },
        (err) => {
          setIsLoaded(false);
          setError(err);
        }
      )
  }, [open]);

  const handleDelete = async (e, val) => {
    let id = parseInt(val.id);
    let states = await get('states');
    let newArr = states.map((v,i) => {
      if (i === id) {return {}}
      else {return v}
    })
    await set(`states`, newArr)
    clearCart();
    setOpen(false);
  }
  
  const handleSave = async (e, val) => {
    props.data.stateId = val.id;
    props.handleData(props.data)
    props.setChange('save');
    clearCart();
    setOpen(false);
  }

  const handleOpen = async (e, val) => {
    let id = val.id
    const loadState = await get(`states`);
    props.data.title = loadState[id].doc.title;
    props.data.template = loadState[id].doc.template;
    props.data.width = loadState[id].doc.width;
    props.data.height = loadState[id].doc.height;
    props.data.templateColor = loadState[id].doc.templateColor;
    props.data.stateId = id;
    props.handleData(props.data);
    props.setChange('open');
    setOpen(false);
  }

  const saveSlotCard = (num) => {
    return {
      key: num,
      header: `Save Slot: ${num}`,
      meta: "Save current design for later",
      
      description: (
        <Button
          fluid
          id={num}
          color="green"
          size="massive"
          icon="plus"
          className="slotBtn"
          onClick={(e, v) => handleSave(e, v)}
        />
      )
    }
  }

  const designCard = (num, title, template, img, modified) => {
    return {
      key: num,
      header: title ? `${title} - ${template}` : "untitled",
      meta: modified ? new Date(modified).toLocaleString() : "missing timestamp",
      image: img ? <Image src={URL.createObjectURL(img)} id={num}/> : null,
      extra: (
        <div>
          <Button.Group widths="2" attached="top">
            <Button
              id={num}
              content="Open"
              color="yellow"
              icon="folder open"
              labelPosition="left"
              onClick={(e, v) => handleOpen(e, v)}
            />
            <Button
              id={num}
              content="Delete"
              color="red"
              icon="trash"
              labelPosition="left"
              onClick={(e, v) => handleDelete(e, v)}
            />
          </Button.Group>
          <Button.Group widths="2" attached="bottom">
            <Button
              id={num}
              content="Save"
              color="blue"
              labelPosition="left"
              icon="save"
              onClick={(e, v) => handleSave(e, v)}
            />
          </Button.Group>
        </div>
      )
    }
  }

  const cardContent = () => {
    if (error) {
      return <h2>Error: {error.message}</h2>;
    } else if (!isLoaded) {
      return <h2>Loading...</h2>;
    } else {
      return <Card.Group id="cardGroup" centered items={cards}/>
    }
  }

    return (
      <Modal
      trigger={
        <Popup
          trigger={
            <Button
              id="saveBtn"
              icon='save'
              inverted
              compact
              onClick={() => setOpen(true)}
              color='blue'
            />
          }
          disabled={props.mobile}
          content="Open / Save..."
          inverted
          mouseEnterDelay={500}
          size='tiny'
          position='bottom left'
          className="popUp"
        />
      }
      content={cardContent()}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      basic
    />
    )
}