import * as PIXI from 'pixi.js';
import * as Victor from '@a-robu/victor';
import randomWords from 'random-words'

class Brush { 
    constructor(o) {
        this.opt = {
            shape: o.shape,
            outline: o.outline,
			size: o.size,
			step: o.step,
            angle: o.angle,
			rotation: o.rotation,
			birthPos: {x: o.birthPos.x, y: o.birthPos.y},
            centerV: {x: o.centerV.x, y: o.centerV.y},
			color: {
				staticColor: o.color.staticColor,
				alpha: o.color.alpha,
				blendMode: o.color.blendMode,
				chaosColor: o.color.chaosColor,
				chaosMode: o.color.chaosMode,
				chaosColorLibrary: o.color.chaosColorLibrary,
			},
			motion: {
				chaosMotion: o.motion.chaosMotion,
				chaosMode: o.motion.chaosMode,
				friction: o.motion.friction,
                dynamics: o.motion.dynamics,
			},
			effects: {
				filter1: o.effects.filter1,
				filter2: o.effects.filter2
			}
        }

        this.brushShapeOptions = {};
        this.variance = Math.random() * 2;
        this.vec = new Victor(this.opt.birthPos.x - this.opt.centerV.x, this.opt.birthPos.y - this.opt.centerV.y);
        this.stopped = false;
        this.maxX = this.opt.centerV.x * 2;
        this.maxY = this.opt.centerV.y * 2;
        this.opt.color.blendMode = this.parseBlendMode(this.opt.color.blendMode);
        this.shapeContainer = new PIXI.Container();

        // if col mode is set to random - pick a new col 
        if (this.opt.color.chaosColor) {
            this.opt.color.staticColor = this.getColorFromLibrary(this.opt.color.chaosColorLibrary);
        }
    }

    HSLToHex(h,s,l) {
        s /= 100;
        l /= 100;
    
        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c/2,
            r = 0,
            g = 0, 
            b = 0; 
    
        if (0 <= h && h < 60) {
        r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16);
        g = Math.round((g + m) * 255).toString(16);
        b = Math.round((b + m) * 255).toString(16);
    
        // Prepend 0s, if necessary
        if (r.length === 1)
        r = "0" + r;
        if (g.length === 1)
        g = "0" + g;
        if (b.length === 1)
        b = "0" + b;
    
        let str = "0x" + r + g + b;
        return parseInt(str);
    }

    getColorFromLibrary(colLib) {
        let options = {
            'random': () => Math.random() * 0xFFFFFF,
            'pastels': () => this.HSLToHex(Math.floor(Math.random() * 360), 100, 80),
            'muted': () => this.HSLToHex(Math.floor(Math.random() * 360), 30, Math.floor(Math.random() * 100)),
            'greys': () => this.HSLToHex(0, 0, Math.floor(Math.random() * 100)),
            'neons': () => this.HSLToHex(Math.floor(Math.random() * 360), 100, 50),
            'vermilions': () => this.HSLToHex(
                Math.floor(Math.random() * 30), 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'oranges': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 30, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'yellows': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 60, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'chartreuses': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 90, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'greens': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 120, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'teals': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 150, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'blues': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 180, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'indigos': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 210, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'violets': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 240, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'purples': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 270, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'magentas': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 300, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'reds': () => this.HSLToHex(
                Math.floor(Math.random() * 30) + 330, 
                Math.floor(Math.random() * 10) + 90, 
                Math.floor(Math.random() * 35) + 35
                ),
            'none': () => this.opt.color.staticColor,
            'default': () => Math.random() * 0xFFFFFF //random by default
        }
        return (options[colLib] || options['default'])();
    }

    applyForce() {
        let force = new Victor(0,0);
        let motion = this.opt.motion.chaosMode;
        let dynamics = this.opt.motion.dynamics;

        force.copy(this.vec);
        force.normalize();

        let dist = this.vec.distance(new Victor(0,0)) * this.opt.step;
        force.setLength(force.length() * dist);

        // if curve motion is selected
        // if curve motion and scatter dynamics is selected
        if (motion === "spiral") {
            if (dynamics === "scatter") {
                force.rotateByDeg(90);
            } else {
                force.rotateDeg(90);
            }
        }

        // if down motion is selected
        if (motion === "down") {
            force.rotateToDeg(90); //TODO - adjust to canvas angle
        }

        // if motion in is selected
        if (motion === 'in') {
            force.invert()
        }
        
        // if motion out, spiral, or down is selected
        if (motion === "out" || "spiral" || "down" || "in" ) {
            this.vec.add(force);
        }

        // if motion spots is selected
        if (motion === "spots") {
            this.vec.subtract(force);
        }

        // post force actions
        // if multi dynamic and out motion is selected
        if (motion === "out") {
            if (dynamics === "multi") {
                this.vec.rotateDeg(90); // multi with outward motion // 180 = 2 brushes 90 = 4 brushes
            } else if (dynamics === "scatter") {
                this.vec.rotateByDeg(90); // multi with scatter                
            }
        }
    }

    move() {
        if (!this.opt.motion.chaosMotion) {
            if (this.opt.color.chaosColor) {
                let newCol = this.getColorFromLibrary(this.opt.color.chaosColorLibrary);              
                let newShape = this.createShape(this.brushShapeOptions, this.opt.shape, this.opt.size, this.opt.birthPos, newCol, this.opt.color.alpha, this.opt.color.blendMode);
                this.shapeContainer.addChild(newShape);      
            } else {
                let newShape = this.createShape(this.brushShapeOptions, this.opt.shape, this.opt.size, this.opt.birthPos, this.opt.color.staticColor, this.opt.color.alpha, this.opt.color.blendMode);
                this.shapeContainer.addChild(newShape);   
            }
            this.stopped = true;
        } else {
            if (this.shapeContainer.children.length > 100) { // max number of shapes 100
                this.stopped = true;
            } else {
                let newPos = new PIXI.Point(this.vec.x + this.opt.centerV.x, this.vec.y + this.opt.centerV.y);

                if ( this.opt.size + this.variance > 12
                    && newPos.x < this.maxX
                    && newPos.y < this.maxY
                    && newPos.x > 0
                    && newPos.y > 0 ) {
                        let newSize = this.opt.size - this.variance - this.opt.motion.friction;
                        let newCol;
                        if (this.opt.color.chaosColor) {
                            newCol = this.opt.color.chaosMode === 'crazy' ? this.getColorFromLibrary(this.opt.color.chaosColorLibrary) : this.opt.color.staticColor;
                        } else {
                            newCol = this.opt.color.staticColor;
                        }
                        
                        let newShape = this.createShape(this.brushShapeOptions, this.opt.shape, this.opt.size, newPos, newCol, this.opt.color.alpha, this.opt.color.blendMode);
                        this.shapeContainer.addChild(newShape);
                        this.opt.size = newSize;
        
                        this.applyForce();
                } else {
                    this.stopped = true;
                }
            }
        }
    }
    createShape (options, sel, size, pos, col, alpha, blendMode) {
        return options[sel](size,pos,col,alpha,blendMode);
    }
    parseBlendMode (sel) {
        let modes = {
            'normal': PIXI.BLEND_MODES.NORMAL,
            'erase': PIXI.BLEND_MODES.ERASE,
            'dodge': PIXI.BLEND_MODES.ADD,
            'burn': PIXI.BLEND_MODES.MULTIPLY,
            'screen': PIXI.BLEND_MODES.SCREEN
        }
        return modes[sel];
    }
}

export class ShapesBrush extends Brush {
    constructor(o) {
        super(o)
        this.brushShapeOptions = {
            'circle': (size,pos,col,alpha,blendMode) => { 
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawCircle(0, 0, size);
                } else {
                    graphic.beginFill(col);
                    graphic.drawCircle(0, 0, size);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                return graphic;
            },
            'square': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawRect(0, 0, size, size);
                } else {
                    graphic.beginFill(col);
                    graphic.drawRect(0, 0, size, size);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return graphic;
            },
            'roundedsquare': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawRoundedRect(0, 0, size, size, 4);
                } else {
                    graphic.beginFill(col);
                    graphic.drawRoundedRect(0, 0, size, size, 4);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return graphic;
            },
            'ellipse': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawEllipse(0, 0, size * 0.5, size);
                } else {
                    graphic.beginFill(col);
                    graphic.drawEllipse(0, 0, size * 0.5, size);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return graphic;
            },
            'star': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawStar(0, 0, 5, size);
                } else {
                    graphic.beginFill(col);
                    graphic.drawStar(0, 0, 5, size);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return graphic;
            },
            'enneagram': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawStar(0, 0, 9, size);
                } else {
                    graphic.beginFill(col);
                    graphic.drawStar(0, 0, 9, size);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return graphic;
            },
            'triangle': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                if (this.opt.outline) {
                    graphic.lineStyle(8,col,alpha);
                    graphic.drawStar(0, 0, 3, size);
                } else {
                    graphic.beginFill(col);
                    graphic.drawStar(0, 0, 3, size);
                    graphic.endFill();
                }
                graphic.alpha = alpha;
                graphic.blendMode = blendMode;
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return graphic;
            },
            'line': (size,pos,col,alpha,blendMode) => {
                let graphic = new PIXI.Graphics();
                graphic.lineStyle(8,col,alpha);
                graphic.moveTo(0, 0);
                graphic.lineTo(0,size + 40);
                graphic.position = pos;
                graphic.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                graphic.blendMode = blendMode;
                return graphic;
            },
        }
    }
} 

export class TextBrush extends Brush {
    constructor(o) {
        super(o)
        this.opt.textOptions = {
            staticText: o.textOptions.staticText,
            fontFamily: o.textOptions.fontFamily,
            fontBold: o.textOptions.fontBold,
            fontItalics: o.textOptions.fontItalics,
            chaosText: o.textOptions.chaosText,
            chaosTextMaxWordLength: o.textOptions.chaosTextMaxWordLength
        }

        this.brushShapeOptions = {
            'text': (size,pos,col,alpha,blendMode) => {
                const style = new PIXI.TextStyle({
                    fontFamily: this.opt.textOptions.fontFamily,
                    fontWeight: this.opt.textOptions.fontBold ? 'bold' : 'normal',
                    fontStyle: this.opt.textOptions.fontItalics ? 'italic' : 'normal',
                    fontSize: parseInt(size),
                    stroke: this.opt.outline ? "#" + Number(Math.floor(col)).toString(16) : null,
                    strokeThickness: this.opt.outline ? 8 : 0,
                    fill: !this.opt.outline ? ["#" + Number(Math.floor(col)).toString(16)] : null
                })

                let words = this.opt.textOptions.chaosText ? 
                    randomWords({exactly: 1, maxLength: this.opt.textOptions.chaosTextMaxWordLength})[0] 
                    : this.opt.textOptions.staticText;

                let text = new PIXI.Text(words, style);
                text.position = pos; 
                text.anchor.set(0.5)
                text.alpha = alpha;
                text.blendMode = blendMode;
                text.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                return text;
            },
        }
    }
} 

export class EmojiBrush extends Brush {
    constructor(o, spriteSheets) {
        super(o)
        this.opt.emojiOptions = {
            tint: o.emojiOptions.tint,
            chaosEmoji: o.emojiOptions.chaosEmoji,
            chaosEmojiLibrary: o.emojiOptions.chaosEmojiLibrary,
            staticEmoji: o.emojiOptions.staticEmoji
        }

        this.spriteSheets = spriteSheets;

        this.brushShapeOptions = {
            'emoji': (size,pos,col,alpha,blendMode) => { 
                let emojiSel = this.parseEmojiLibrary(this.opt.emojiOptions.chaosEmojiLibrary); 
                let length = Object.keys(this.spriteSheets[emojiSel].textures).length
                let i = Math.floor(Math.random() * length);
                let sel = Object.values(this.spriteSheets[emojiSel].textures)[i];
                const newEmoji = new PIXI.Sprite(sel);
                newEmoji.anchor.set(0.5);
                newEmoji.tint = col
                newEmoji.width = size;
                newEmoji.height = size;
                newEmoji.angle = this.opt.rotation ? Math.floor(Math.random() * 360) : -this.opt.angle;
                newEmoji.position = pos;
                newEmoji.alpha = alpha;
                newEmoji.blendMode = blendMode;
                return newEmoji;
            },
        }
    }
    parseEmojiLibrary(sel) {
        let opt = {
            'all': Math.floor(Math.random() * this.spriteSheets.length),
            'animals': 0,
            'flags': 1,
            'food': 2,
            'misc': 3,
            'nature': 4,
            'objects': Math.floor(Math.random() + 5.5),
            'people': 7,
            'smilies': 8,
            'symbols': 9,
            'travel': 11
        }
        return opt[sel];
    }
} 

export class EraserBrush extends ShapesBrush {} 