import randomWords from 'random-words'
let width, height, maxTextureSize;

const detectMaxSize = () => {	
	const gl = document.createElement('canvas').getContext('webgl');
	maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
	if (maxTextureSize === 4096 ) {
		width = 3072;
		height = 4096;
	} else if (maxTextureSize > 4096) {
		width = 3540;
		height = 4720;
	} else if (maxTextureSize < 4096) {
		width = 1000;
		height = 1000;
	}
}

detectMaxSize();

export const paintSysState = {
	application: {
		width: window.innerWidth,
		height: window.innerHeight,
		backgroundColor: 0x1d272e,
		resolution: window.devicePixelRatio,
		autoDensity: true,
		resizeTo: document.body,
	},
	document: { 
		stateId: 0,
		width: width,
		height: height,
		title: randomWords({ exactly: 2, join: ' ', formatter: (word) => word.slice(0,1).toUpperCase().concat(word.slice(1))}),
		spin: false,
		reset: false,
		fill: false,
		fillColor: 3319890,
		template: 'tshirt',
		templateColor: '#ffffff',
		templateColorText: 'White',
		maxTextureSize: maxTextureSize
	},
	input: {
		cursorStyles: {
			default: 'brush',
			hand: 'grab',
			handDown: 'grabbing',
			brush: 'crosshair',
			zoomIn: 'zoom-in',
			zoomOut: 'zoom-out',
			fill: 'crosshair'
		}
	},
	tools: {
		selected: 'shapesBrush',
		centerV: {
			x: 0,
			y: 0
		},
		shapesBrush: {
			shape: 'circle',
			outline: false,
			size: 60,
			step: 0.04,
			angle: 0,
			rotation: false,
			birthPos: {x: 0, y: 0},
			centerV: {x: 0, y: 0},
			color: {
				staticColor: 3319890,
				alpha: 1,
				blendMode: 'normal',
				chaosColor: true,
				chaosMode: 'crazy',
				chaosColorLibrary: 'random',
			},
			motion: {
				chaosMotion: true,
				chaosMode: 'out',
				friction: 0.5,
				dynamics: 'none',
			},
			effects: {
				filter1: 'none',
				filter2: 'none'
			}
		},
		emojiBrush: {
			shape: 'emoji',
			outline: false,
			size: 100,
			step: 0.04,
			angle: 0,
			rotation: false,
			birthPos: {x: 0, y: 0},
			centerV: {x: 0, y: 0},
			color: {
				staticColor: 0xffffff,
				alpha: 1,
				blendMode: 'normal',
				chaosColor: false,
				chaosMode: 'crazy',
				chaosColorLibrary: 'random',
			},
			motion: {
				chaosMotion: false,
				chaosMode: 'out',
				friction: 0.5,
				dynamics: 'none'
			},
			effects: {
				filter1: 'none',
				filter2: 'none'
			},
			emojiOptions: {
				tint: false,
				chaosEmoji: true,
				chaosEmojiLibrary: 'smilies',
				staticEmoji: ''
			}
		},
		textBrush: {
			shape: 'text',
			outline: false,
			size: 100,
			step: 0.6,
			angle: 0,
			rotation: false,
			birthPos: {x: 0, y: 0},
			centerV: {x: 0, y: 0},
			color: {
				staticColor: 3319890,
				alpha: 1,
				blendMode: 'normal',
				chaosColor: true,
				chaosMode: 'crazy',
				chaosColorLibrary: 'muted',
			},
			motion: {
				chaosMotion: false,
				chaosMode: 'out',
				friction: 0.5,
				dynamics: 'none'
			},
			effects: {
				filter1: 'none',
				filter2: 'none'
			},
			textOptions: {
				staticText: 'Hello Wizard!',
				fontFamily: 'Arial',
				fontBold: false,
				fontItalics: false,
				chaosText: true,
				chaosTextMaxWordLength: 8
			}
		},
		eraserBrush: {
			shape: 'circle',
			outline: false,
			size: 60,
			step: 0.06,
			angle: 0,
			rotation: false,
			birthPos: {x: 0, y: 0},
			centerV: {x: 0, y: 0},
			color: {
				staticColor: 0,
				alpha: 0.8,
				blendMode: 'erase',
				chaosColor: false,
				chaosMode: 'crazy',
				chaosColorLibrary: 'random',
			},
			motion: {
				chaosMotion: false,
				chaosMode: 'out',
				friction: 0.1,
				dynamics: 'none',
			},
			effects: {
				filter1: 'none',
				filter2: 'none'
			}
		},
		fill: {
			staticColor: 3319890,
			alpha: 1
		}
	},
}