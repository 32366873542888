import { Button, Form, Card, Dimmer, Loader, Message } from 'semantic-ui-react';
import tshirtTemplateImg from '../../assets/tshirtTemplateBGFilled-sm.webp';
import tshirtProduct from '../../assets/product-tshirt.json';
import { useShoppingCart } from 'use-shopping-cart';
import { useState } from 'react'
import { get, update } from 'idb-keyval';
import './SummonCard.css';
import axios from 'axios'

const parseTemplateName = (name) => {
	const options = {
		tshirt: "TShirt"
	}
	return options[name]
}

export const SummonCard = (props) => {
	const { addItem } = useShoppingCart();
	const [tshirtSize, setTshirtSize] = useState("M");
	const [tshirtSizeText, setTshirtSizeText] = useState('Medium');
	const imgUrl = props.img instanceof Blob ? URL.createObjectURL(props.img) : props.img
	const tshirtDescription = <a href={tshirtProduct.descriptionLink} target="_blank" rel="noreferrer">{tshirtProduct.description}</a>
    const [tshirtColor, setTshirtColor] = useState('#ffffff');
	const [tshirtColorText, setTshirtColorText] = useState('White');
    const [tshirtQuantity, setTshirtQuantity] = useState(1)
	const [uploading, setUploading] = useState(false);
	const [uploaded, setUploaded] = useState(props.uploaded);
	const [hideError, setHideError] = useState(true);

	const handleTshirtSize = (e, size) => {
		setTshirtSize(size.value)
		setTshirtSizeText(e.target.innerText)
	}
	
	const handleTshirtColor = (e, color) => {
		setTshirtColor(color.value)
		setTshirtColorText(e.target.innerText)
	}

	const handleTshirtQuantity = (amount) => {
		setTshirtQuantity(amount.value)
	}

	const addProduct = () => {
		addItem({
			id: props.uuid + "-" + tshirtColor + "-" + tshirtSize,
			sku: props.uuid,
			name: props.title,
			description: `${props.title} - ${parseTemplateName(props.template)} - ${props.price}`,
			size: tshirtSize,
			sizeText: tshirtSizeText,
			colorText: tshirtColorText,
			template: props.template,
			price: tshirtProduct.price,
			currency: 'USD',
			imgSize: props.img.size,
			author: props.author,
			showcase: props.showcase
		}, tshirtQuantity)
		props.openPortal();
	}

	const uploadDesign = async () => {
		try {
			setUploading(true)

			const url = './.netlify/functions/upload-design'
			const tmpStates = await get(`states`);
			const data = {
				title: tmpStates[props.num].doc.title,
				template: tmpStates[props.num].doc.template,
				uuid: tmpStates[props.num].uuid
			}
			const requestPost = await axios.post(url, data);
			await axios.put(requestPost.data.url, tmpStates[props.num].img, {headers: {'content-type': 'image/png'}});

			await update('states', (val) => {
				val[props.num].uploaded = true;
				return val;
			});
			setUploading(false);
			setUploaded(true)
			addProduct();
		} catch (err) {
			setUploading(false);
			setHideError(false)
		}
	}

	const handleDismiss = () => {
		setHideError(true);
	}
	return (
		<Card
			raised={true}
			className="summonCard"
		>
			<div className="tshirtTemplateContainer">
				<img alt="Tshirt Template" src={tshirtTemplateImg} style={{backgroundColor: tshirtColor}} className="tshirtTemplate" />
				<img alt="Tshirt Design" src={imgUrl} className="tshirtDesign" />
			</div>
			<Card.Content>
				<Dimmer active={uploading}>
					<Loader size="large">Uploading Design</Loader>
			  	</Dimmer>
				<Dimmer active={!hideError}>
					<Message 
					icon="warning circle" 
					header="Upload Failed" 
					error 
					hidden={hideError}
					onDismiss={handleDismiss}
					content="Please close this message and try again"
					/>
			  	</Dimmer>
				<Card.Header>
					{`${props.title} - ${parseTemplateName(props.template)} - ${props.price}`}
				</Card.Header>
				<Card.Description>
					{ tshirtDescription }
				</Card.Description>
			</Card.Content>
			<Card.Content extra>
				<Form>
					<Form.Group inline>
						<Form.Select
							inline
							label="Size"
							onChange={(e, size) => handleTshirtSize(e, size)}
							options={tshirtProduct.sizes}
							value={tshirtSize}
						/>
						<Form.Select
							inline
							label="Color"
							onChange={(e, template) => handleTshirtColor(e, template)}
							options={tshirtProduct.colors}
							value={tshirtColor}
						/>
						<Form.Select
							inline
							label="Quantity"
							compact
							onChange={(e, amount) => handleTshirtQuantity(amount)}
							options={tshirtProduct.quantity}
							value={tshirtQuantity}
						/>
					</Form.Group>
					<Button fluid color="green"
					onClick={() => {
						!uploaded ? uploadDesign() : addProduct();
					}}
					content={uploaded ? "Add to Cart" : "Upload and Add to Cart"}
					/>
				</Form>
			</Card.Content>
		</Card>
	)
}