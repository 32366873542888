import { Menu, Button, Form, Popup, Divider } from 'semantic-ui-react';
import { SaveModal } from './SaveModal';
import { NewModal } from './NewModal';
import { RenameModal } from './RenameModal';
import { ShareModal } from './ShareModal';
import { TshirtColorSelect } from './TshirtColorSelect';
import './CanvasMenu.css'

export const CanvasMenu = (props) => {

	const handleData = () => {
		props.setData(data => ({ ...data }))
	}

	return (
		<Menu.Item>
			<Menu.Menu>
				<div className="canvasMenuItem">
					<NewModal mobile={props.mobile} data={props.data}  setChange={props.setChange} handleData={handleData} />
					<SaveModal mobile={props.mobile} data={props.data} setChange={props.setChange} handleData={handleData} />
					<Popup
						trigger={
							<Button
								id="downloadBtn"
								icon='download'
								inverted
								compact
								color='purple'
								onClick={() => props.setChange('export')}
							/>
						}
						disabled={props.mobile}
						content="Export (CTRL + E)"
						inverted
						mouseEnterDelay={500}
						size='tiny'
						position='bottom left'
						className="popUp"
					/>
					<ShareModal data={props.data} setChange={props.setChange} handleData={handleData} paintSys={props.paintSys} />
					<Popup
						trigger={
							<Button
								id="spinBtn"
								icon='spinner'
								inverted
								compact
								color='yellow'
								onClick={() => props.setChange('spin')}
							/>
						}
						disabled={props.mobile}
						content="Spin Canvas (CTRL + D)"
						inverted
						mouseEnterDelay={500}
						size='tiny'
						position='bottom left'
						className="popUp"
					/>
				</div>
				<Form inverted>
					<Form.Field>
						<RenameModal mobile={props.mobile} data={props.data} handleData={handleData}/>
						{ props.data.template === 'tshirt' && <TshirtColorSelect data={props.data} handleData={handleData} setChange={props.setChange}/>}
						<Divider fitted />
					</Form.Field>
				</Form>
			</Menu.Menu>
		</Menu.Item>
	)
}

export default CanvasMenu;