import { Card, Pagination, Container } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { ShowcaseCard } from './ShowcaseCard';
import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3"
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity"
const chunk = require('lodash.chunk');

export const Gallery = () => {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cards, setCards] = useState([]);
    const [pages, setPages] = useState([]);
    const [numOfPages, setNumOfPages] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const cardsPerPage = 8;

    useEffect(() => {
        const cognitoIdentityClient = new CognitoIdentityClient({ region: "us-east-2" });
        const s3Client = new S3Client({
            region: "us-east-2",
            credentials: fromCognitoIdentityPool({
                client: cognitoIdentityClient,
                identityPoolId: 'us-east-2:306b08f3-280c-4d5a-bd9c-a48529365734'
            })
        });

        const params = {
            Bucket: "mancer-upload-public",
            Prefix: "quarter/",
            StartAfter: "quarter/"
        }

        const command = new ListObjectsV2Command(params);

        s3Client.send(command)
        .then(
            res => {
                if (res.KeyCount === 0) {
                    setIsLoaded(false);
                    setError("No designs in Showcase")
                } else {
                    setIsLoaded(true);
                    setNumOfPages(Math.ceil(res.KeyCount / cardsPerPage))

                    let pageArr = chunk(res.Contents, cardsPerPage);
                    setPages(pageArr);

                    let newArr = pageArr[0].map((v, i) => {
                        return (ShowcaseCard(v, i))
                    })
                    setCards(newArr)       
                }
            },
            err => {
                setIsLoaded(false);
                setError("Could not load cards");
            }
        )
    }, [])

    const openPage = (e, val) => {
        let num = val.activePage - 1;
        let newArr = pages[num].map((v, i) => {
            return (ShowcaseCard(v, i))
        })
        setCards(newArr);
        setActivePage(val.activePage)
    }

    const PageSelect = () => (
        <Pagination 
            activePage={activePage}
            siblingRange={1}
            totalPages={numOfPages}
            inverted
            secondary
            pointing
            onPageChange={(e, val) => openPage(e, val)}
            size="large"
        />
    )

    if (error) {
        return (
            <Container style={{marginTop: '1em'}} textAlign="center">
                <h2 className="lightText"> (‍╯‍°‍□‍°‍)‍╯‍ {error}</h2>;
            </Container>
        )
      } else if (!isLoaded) {
          return (
            <Container style={{marginTop: '1em'}} textAlign="center">
                <h2 className="lightText"> Loading...</h2>;
            </Container>
          )
      } else {
        return (
            <>
                <Container style={{marginTop: '1em'}} textAlign="center" fluid>
                    <PageSelect/> 
                </Container>
                <Card.Group style={{ padding: '2em' }} centered items={cards} />
            </>
        )
      }
}