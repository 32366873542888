import { Menu, Icon, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useShoppingCart } from 'use-shopping-cart';

export const TopMenu = (props) => {
    const { cartCount } = useShoppingCart();
    return (
        <>
            { props.mobile ?
                <Menu secondary inverted widths={5}>
                    <Menu.Item as={Link} to="/"> <img alt="Mancer icon" src="./mancer-logo-icon.png" height="28px" /> </Menu.Item>
                    <Menu.Item as={Link} to="/create"> <Icon name="paint brush" /> </Menu.Item>
                    <Menu.Item as={Link} to="/showcase"> <Icon name="picture" /> </Menu.Item>
                    <Menu.Item as={Link} to="/summon"> <Icon name="magic" /> </Menu.Item>
                    <Menu.Item as={Link} to="/cart">
                        <Icon name="cart" />
                        {cartCount !== 0 && <Label color="purple" content={cartCount} />}
                    </Menu.Item>
                </Menu>
                :
                <Menu secondary inverted>
                    <Menu.Item as={Link} to="/"> <img alt="Mancer icon" src="./mancer-logo-icon.png" height="28px" /> </Menu.Item>
                    <Menu.Item as={Link} to="/create"> <Icon name="paint brush" /> Create</Menu.Item>
                    <Menu.Item as={Link} to="/showcase"> <Icon name="picture" /> Showcase</Menu.Item>
                    <Menu.Item as={Link} to="/summon"> <Icon name="magic" /> Summon</Menu.Item>
                    <Menu.Item as={Link} to="/cart">
                        <Icon name="cart" />
                        Cart
                        {cartCount !== 0 && <Label color="purple" content={cartCount} />}
                    </Menu.Item>
                </Menu>
            }
        </>
    )
}
