import { Button, Modal, Popup, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import { useState } from 'react';
import { get } from 'idb-keyval';
import './ShareModal.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios'
import randomWords from 'random-words'
import Joi from 'joi'
const titles = ["The Great", "The Powerful", "The Magnificent", "Captain", "Wizard", "Admiral"]

export const ShareModal = (props) => {
	const [open, setOpen] = useState(false);
	const [author, setAuthor] = useState(`${titles[Math.floor(Math.random() * titles.length)]} ${randomWords({ exactly: 1, formatter: (word) => word.slice(0, 1).toUpperCase().concat(word.slice(1))})}`);
	const [authorError, setAuthorError] = useState(null);
	const [license, setLicense] = useState(false);
	const [captcha, setCaptcha] = useState(false);
	const [formError, setFormError] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [failed, setFailed] = useState(false);
	const [duplicateFail, setDuplicateFail] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);

	const handleAuthorChange = (author) => {
		const authorSchema = Joi.string().trim().min(3).max(30).regex(/^\w+(?:\s+\w+)*$/);
		const { error } = authorSchema.validate(author)
		if (error) {
			setAuthorError('Only letters, spaces, numbers, and between 3 - 30 characters')
		} else {
			setAuthorError(null)
		}
		setAuthor(author);
	}

	const uploadDesign = async (author) => {
        const url = './.netlify/functions/share-design'

        try {
			const tmpState = await get('temp-state');

			if (tmpState.img.size > 30000000 || tmpState.img.size < 10000) { throw new Error("error") }
			else if (tmpState.imgQuarter.size > 30000000 || tmpState.imgQuarter.size < 100) { throw new Error("error") }

			const data = {
				author: author,
				title: tmpState.doc.title,
				template: tmpState.doc.template,
				templateColorText: tmpState.doc.templateColorText,
				width: tmpState.doc.width,
				height: tmpState.doc.height,
				uuid: tmpState.uuid,
				imgSize: tmpState.img.size,
				imgType: tmpState.img.type,
				imgQuarterSize: tmpState.imgQuarter.size,
				imgQuarterType: tmpState.imgQuarter.type,
				captchaToken: captchaToken,
			}
            const requestPost = await axios.post(url, data);
			await axios.put(requestPost.data.url, tmpState.img, {headers: {'content-type': 'image/png'}});
			await axios.put(requestPost.data.urlQuarter, tmpState.imgQuarter, {headers: {'content-type': 'image/webp'}});

        } catch (err) {
			throw err
        }
    }

	const handleFormSubmit = async () => {
		if (!license) {
			setFormError("To share you must agree to license this work")
		} 
		else if (!captcha) {
			setFormError("Please confirm that you are human")
		} 
		else if (authorError) {
			setFormError("Please fix author name")
		}
		else {
			setFormError(null)
			setUploading(true);
			try {
				if (!success) {
					await props.paintSys.saveDocument(props.data, true);
					await uploadDesign(author);
					setSuccess(true);
					setUploading(false);
					setFailed(false);
				} 
				else {
				 	setUploading(false);
					setDuplicateFail(true);
				}
			} catch (err) {
				setUploading(false);
				setFailed(true);
			}
		}
	}

	const handelOpen = () => {
		setOpen(!open)
	}

	const handleVerificationSuccess = (token) => {
		setCaptchaToken(token);
		setCaptcha(true);
	}

	const SharePanel = (
		<>
			<Dimmer active={uploading}>
				<Loader>Uploading Design</Loader>
			</Dimmer>
			<Message hidden={!duplicateFail} negative>(‍╯‍°‍□‍°‍)‍╯‍ design already shared</Message>
			<Message hidden={!failed} negative>(‍╯‍°‍□‍°‍)‍╯‍ something went wrong</Message>
			<Message hidden={!success} positive>Great Success!</Message>
			<Form className="shareForm" error={formError}>
				<Form.Field>
					<label>Author</label>
					<Form.Input
						value={author}
						error={authorError}
						onChange={(e) => handleAuthorChange(e.target.value)}
					/>
				</Form.Field>
				<Form.Checkbox
					label={{ children: <a href="https://creativecommons.org/share-your-work/public-domain/cc0/" target="_blank" rel="noreferrer">I agree to license this design as CCO </a>}}
					required
					onChange={() => setLicense(!license)}
					checked={license}
				>
				</Form.Checkbox>
				<HCaptcha
					sitekey="76aebf0f-26cf-4dc0-abc2-71b8e65ee851"
					onVerify={(token) => handleVerificationSuccess(token)}
				/>
				<Message
					error
					header='Form Error'
					content={formError}
				/>
				<Form.Group widths="equal">
					<Form.Button
						content="Share"
						color="green"
						fluid
						onClick={handleFormSubmit}
					/>
					<Form.Button
						content="Cancel"
						color="red"
						fluid
						onClick={() => setOpen(false)}
					/>
				</Form.Group>
			</Form>
		</>
	)

	const cannotSharePanel = (
		<Message
		style={{margin: '2em'}}
		error
		header='Cannot Share'
		content="Cannot share non template designs, currently you can only share Tshirt designs."
		/>
	)

	return (
		<Modal
			trigger={
				<Popup
					trigger={
						<Button
							id="shareBtn"
							icon='share'
							inverted
							compact
							color='orange'
							onClick={handelOpen}
						/>
					}
					disabled={props.mobile}
					content="Share..."
					inverted
					mouseEnterDelay={500}
					size='tiny'
					position='bottom left'
					className="popUp"
				/>
			}
			header="Share Current Design"
			content={props.data.template === "custom" ? cannotSharePanel : SharePanel}
			closeIcon
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			size="small"
		/>
	)
}