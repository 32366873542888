import * as PIXI from 'pixi.js';
import { EmojiBrush, ShapesBrush, TextBrush, EraserBrush } from './Brush';
import { AsciiFilter, BevelFilter, DotFilter, DropShadowFilter, GlowFilter, KawaseBlurFilter, OutlineFilter, PixelateFilter, TwistFilter } from 'pixi-filters';
import { Viewport } from 'pixi-viewport';
import tshirtTemplate from '../../assets/tshirtTemplateWhiteThin.webp'
import { get, set } from 'idb-keyval';
const { v4: uuidv4 } = require('uuid');

const filters = {
    pixelate: (pos, scale) => new PixelateFilter(8),
    bevel: (pos, scale) => new BevelFilter({thickness: 5}),
    ascii: (pos, scale) => new AsciiFilter(12),
    twist: (pos, scale) => new TwistFilter({offset: pos, radius: scale * 2}),
    dot: (pos, scale) => new DotFilter(0.5),
    dropshadow: (pos, scale) => new DropShadowFilter({distance: 10}),
    glow: (pos, scale) => new GlowFilter(),
    kawaseblur: (pos, scale) => new KawaseBlurFilter(0.5),
    outline: (pos, scale) => new OutlineFilter(5),
    noise: (pos, scale) => new PIXI.filters.NoiseFilter()
}

export class PaintSystem {
    constructor(psState) {
        PIXI.utils.skipHello();
        this.app = new PIXI.Application(psState.application);
        this.doc = psState.document;
        this.tol = psState.tools;
        this.in = psState.input;
        this.in.dragging = false;
        this.brushes = [];
        this.renderTexture = {};
        this.renderTextureSprite = {};
        this.renderTextureTemp = {};
        this.renderTextureSpriteTemp = {};
        this.rotContainer = {};
        this.undone = false;
        this.eraserLastUsed = false;
        this.spriteSheets = [];
        this.mobile = PIXI.utils.isMobile.any;

        this.tshirtSprite = new PIXI.Sprite.from(tshirtTemplate);
        this.app.renderer.plugins.interaction.moveWhenInside = false;

        this.pointerDown = this.pointerDown.bind(this);
        this.pointerUp = this.pointerUp.bind(this);
        this.pointerMove = this.pointerMove.bind(this);
        this.animate = this.animate.bind(this);
        this.setupRotatingContainer = this.setupRotatingContainer.bind(this);
        this.setupRenderTexture = this.setupRenderTexture.bind(this);
        this.assetsLoaded = this.assetsLoaded.bind(this);

        this.app.stage.interactive = true;
        this.app.stage.on('pointerdown', this.pointerDown);
        this.app.stage.on('pointerup', this.pointerUp);
        this.app.stage.on('pointermove', this.pointerMove);

        this.app.renderer.plugins.interaction.cursorStyles = this.in.cursorStyles
        this.app.renderer.plugins.interaction.setCursorMode('brush');

        this.app.loader
            .add('ssAnimals', 'emoji/animals/animals128-full.json')
            .add('ssFlags', 'emoji/flags/flags128-full.json')
            .add('ssFood', 'emoji/food/food128-full.json')
            .add('ssMisc', 'emoji/misc/misc128-full.json')
            .add('ssNature', 'emoji/nature/nature128-full.json')
            .add('ssObjects0', 'emoji/objects/objects128-full-0.json')
            .add('ssObjects1', 'emoji/objects/objects128-full-1.json')
            .add('ssPeople', 'emoji/people/people128-full.json')
            .add('ssSmilies', 'emoji/smilies/smilies128-full.json')
            .add('ssSymbols', 'emoji/symbols/symbols128-full.json')
            .add('ssTravel', 'emoji/travel/travel128-full.json')
            .load(this.assetsLoaded)

        this.applyTemplate();
        this.animate();
    }

    assetsLoaded(loader, resources) {
        this.spriteSheets = [
            resources.ssAnimals,
            resources.ssFlags,
            resources.ssFood,
            resources.ssMisc,
            resources.ssNature,
            resources.ssObjects0,
            resources.ssObjects1,
            resources.ssPeople,
            resources.ssSmilies,
            resources.ssSymbols,
            resources.ssTravel,
        ];
    }
    
    applyTemplate() {
        this.setupViewport(this.app.screen.width, this.app.screen.height)
        this.setupRotatingContainer(this.doc.width, this.doc.height);
        this.setupRenderTexture(this.doc.width, this.doc.height, 'main');
        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
    }

    setupViewport(width, height) {
        this.viewport = new Viewport({
            screenWidth: width,
            screenHeight: height,
            worldWidth: width * 2,
            worldHeight: height * 2,
            disableOnContextMenu: true,
            interaction: this.app.renderer.plugins.interaction
        })

        this.app.stage.addChild(this.viewport)
        this.viewport
            .drag({mouseButtons: "middle-right", pressDrag: !this.mobile})
            .pinch()
            .wheel({ percent: -1 })
            .decelerate()
            .fit(true, 5200, 4767)
    }

    parseTemplateColor(col) {
        return PIXI.utils.string2hex(col)
    }

    setupRotatingContainer(width, height) {
        let centerX, centerY, drawWidth, drawHeight;        
        if (this.doc.template === "tshirt") {
            centerX = this.doc.width / 4;
            centerY = this.doc.height / 4;
            drawWidth = this.doc.width / 2;
            drawHeight = this.doc.height / 2;
        } else {
            centerX = width / 2;
            centerY = height / 2;
            drawWidth = width;
            drawHeight = height;
        }

        this.rotContainer = new PIXI.Container();
        this.rotContainer.position.x = centerX;
        this.rotContainer.position.y = centerY;
        this.rotContainer.pivot.set(centerX, centerY);

        if (this.doc.template === "tshirt") {
            this.tshirtSprite.tint = this.parseTemplateColor(this.doc.templateColor);
            if (this.doc.height === 4096) {
                this.tshirtSprite.width = 4527
                this.tshirtSprite.height = 4150;
                this.tshirtSprite.position.x = -1500;
                this.tshirtSprite.position.y = -950;
            } else { // height 4720
                this.tshirtSprite.width = 5200
                this.tshirtSprite.height = 4767;
                this.tshirtSprite.position.x = -1712;
                this.tshirtSprite.position.y = -1100;
            } 
            this.rotContainer.addChild(this.tshirtSprite)
        }

        let border = new PIXI.Graphics();
        const t1 = new PIXI.Graphics()
        .beginFill(0x000000)
        .drawRect(0, 0, 22, 1)
        .beginFill(0xffffff)
        .drawRect(22, 0, 42, 1)
        .endFill()
        const hTexture = this.app.renderer.generateTexture(t1);
        const t2 = new PIXI.Graphics()
        .beginFill(0x000000)
        .drawRect(0, 0, 1, 22)
        .beginFill(0xffffff)
        .drawRect(0, 22, 1, 42)
        .endFill()
        const vTexture = this.app.renderer.generateTexture(t2);

        border.lineTextureStyle({ width: 16, texture: hTexture, alpha: 0.5})
        border.moveTo(0, 0);
        border.lineTo(drawWidth, 0);
        border.moveTo(0, drawHeight);
        border.lineTo(drawWidth, drawHeight);
        
        border.lineTextureStyle({width: 16, texture: vTexture, alpha: 0.5});
        border.moveTo(0, 0);
        border.lineTo(0, drawHeight);
        border.moveTo(drawWidth, 0);
        border.lineTo(drawWidth, drawHeight);

        this.rotContainer.addChild(border)
        this.viewport.addChild(this.rotContainer);

        this.tol.centerV.x = centerX;
        this.tol.centerV.y = centerY;
    }

    setupRenderTexture(width, height, type) {
        let drawWidth, drawHeight, resolution;
        if (this.doc.template === "tshirt") {
            drawWidth = this.doc.width / 2;
            drawHeight = this.doc.height / 2;
            resolution = 2;
        } else {
            drawWidth = width;
            drawHeight = height;
            resolution = 1;
        }

        if (type === 'main') {
            this.renderTexture = PIXI.RenderTexture.create({
                width: drawWidth,
                height: drawHeight,
                scaleMode: 0,
                resolution: resolution
            });
            this.renderTextureSprite = new PIXI.Sprite(this.renderTexture);
            this.rotContainer.addChild(this.renderTextureSprite);

        } else if (type === 'temp') {
            this.renderTextureTemp = PIXI.RenderTexture.create({
                width: drawWidth,
                height: drawHeight,
                scaleMode: 0,
                resolution: resolution
            });
            this.renderTextureSpriteTemp = new PIXI.Sprite(this.renderTextureTemp);
            this.rotContainer.addChild(this.renderTextureSpriteTemp);
        }
    }

    undo() {
        if (this.eraserLastUsed) { // act like redo()
            this.rotContainer.addChild(this.renderTextureSpriteTemp);
            this.undone = false;
        } else {
            this.rotContainer.removeChild(this.renderTextureSpriteTemp);
            this.undone = true;
        }
    }

    redo() {
        if (this.eraserLastUsed) { // act like undo()
            this.rotContainer.removeChild(this.renderTextureSpriteTemp);
            this.undone = true;
        } else {
            this.rotContainer.addChild(this.renderTextureSpriteTemp)
            this.undone = false;
        }
    }

    pointerDown(e) {
        let tool = this.tol.selected;
        let btn = e.data.button;
        if (this.mobile) {btn = 0}

        if (tool === 'shapesBrush' || tool === 'emojiBrush' || tool === 'textBrush') {
            if (btn === 0) { // left click
                let x = e.data.getLocalPosition(this.rotContainer).x;
                let y = e.data.getLocalPosition(this.rotContainer).y;

                if ((y > this.doc.height || y < 0) || (x > this.doc.width || x < 0)) { return }
                else {
                    if (this.undone) {
                        this.renderTextureSpriteTemp.destroy(true);
                        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
                        this.undone = false;
                    } else {
                        this.app.renderer.render(this.renderTextureSpriteTemp, this.renderTexture, false, null, false) // by it self this causes problems with transperancy
                        this.renderTextureSpriteTemp.destroy(true);
                        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
                    }
                    this.in.dragging = true;
                    this.pointerMove(e);
                }
            }
        }

        if (tool === 'eraserBrush') {
            if (btn === 0) {
                let x = e.data.getLocalPosition(this.rotContainer).x;
                let y = e.data.getLocalPosition(this.rotContainer).y;

                if ((y > this.doc.height || y < 0) || (x > this.doc.width || x < 0)) { return }
                else {
                    if (this.undone) {
                        this.renderTextureSpriteTemp.destroy(true);
                        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
                        this.undone = false;
                    } else {
                        this.app.renderer.render(this.renderTextureSpriteTemp, this.renderTexture, false, null, false);
                        this.renderTextureSpriteTemp.destroy(true);
                        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
                    }
                    this.in.dragging = true;
                    this.pointerMove(e);
                }
            }
        }

        if (tool === 'hand') {
            if (btn === 0) {
                this.app.renderer.plugins.interaction.setCursorMode('handDown');
            }
        }

        if (tool === 'zoomIn') {
            if (btn === 0) {
                this.viewport.zoomPercent(0.1, true);
            }
        }

        if (tool === 'zoomOut') {
            if (btn === 0) {
                this.viewport.zoomPercent(-0.1, true);
            }
        }

        if (tool === 'fill') {
            if (btn === 0) {
                let x = e.data.getLocalPosition(this.rotContainer).x;
                let y = e.data.getLocalPosition(this.rotContainer).y;

                if ((y > this.doc.height || y < 0) || (x > this.doc.width || x < 0)) { return }
                else {

                    if (this.undone) {
                        this.renderTextureSpriteTemp.destroy(true);
                        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
                        this.undone = false;
                    } else {
                        this.app.renderer.render(this.renderTextureSpriteTemp, this.renderTexture, false, null, false)
                        this.renderTextureSpriteTemp.destroy(true);
                        this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
                    }
                    this.applyFill();
                }
            }
        }
    }

    pointerUp() {
        if (this.tol.selected === 'hand') {
            this.app.renderer.plugins.interaction.setCursorMode('hand');
        }
        this.in.dragging = false;
    }

    createBrushBySelection(sel, opt, spriteSheets) {
        let brushOpt = {
            'shapesBrush': () => new ShapesBrush(opt),
            'emojiBrush': () => new EmojiBrush(opt, spriteSheets),
            'textBrush': () => new TextBrush(opt),
            'eraserBrush': () => new EraserBrush(opt)
        }
        if (spriteSheets) {
            return brushOpt[sel](opt, spriteSheets);
        } else {
            return brushOpt[sel](opt);
        }
    }

    pointerMove(e) {
        if (this.in.dragging) {
            let x = e.data.getLocalPosition(this.rotContainer).x;
            let y = e.data.getLocalPosition(this.rotContainer).y;
            if ((y > this.doc.height || y < 0) || (x > this.doc.width || x < 0)) { return }
            else {
                let opt = this.tol[this.tol.selected]
                opt.centerV = this.tol.centerV;
                opt.birthPos = e.data.getLocalPosition(this.rotContainer);
                opt.angle = this.rotContainer.angle;
                opt.single = true;
                let newBrush;

                if (this.tol.selected === 'emojiBrush') {
                    newBrush = this.createBrushBySelection(this.tol.selected, opt, this.spriteSheets);
                } else {
                    newBrush = this.createBrushBySelection(this.tol.selected, opt);
                }
                this.brushes.push(newBrush);
                this.rotContainer.addChild(newBrush.shapeContainer);
            }
        }
    }

    setBrushMode(tool) {
        this.tol.selected = tool;
    }

    rotate45() {
        this.rotContainer.angle += 45 - (this.rotContainer.angle % 45);
    }

    changeMouse(tool) {
        const changeCursor = (key) => {
            this.app.renderer.plugins.interaction.cursorStyles.default = key;
            this.app.renderer.plugins.interaction.setCursorMode(key);
        }

        if (tool === 'hand') {
            this.viewport.drag({ mouseButtons: 'left-middle-right' });
            this.mobile && this.viewport.drag({pressDrag: true})
            changeCursor('hand');
        }
        if (tool === 'shapesBrush') {
            this.viewport.drag({ mouseButtons: 'middle-right' })
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('brush');
            this.tol.selected = tool;
        }

        if (tool === 'zoomIn') {
            this.viewport.drag({ mouseButtons: 'middle-right' });
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('zoomIn');
        }

        if (tool === 'zoomOut') {
            this.viewport.drag({ mouseButtons: 'middle-right' });
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('zoomOut');
        }

        if (tool === 'textBrush') {
            this.viewport.drag({ mouseButtons: 'middle-right' });
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('brush');
        }

        if (tool === 'emojiBrush') {
            this.viewport.drag({ mouseButtons: 'middle-right' });
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('brush');
        }

        if (tool === 'eraserBrush') {
            this.viewport.drag({ mouseButtons: 'middle-right' });
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('brush');
        }

        if (tool === 'fill') {
            this.viewport.drag({ mouseButtons: 'middle-right' });
            this.mobile && this.viewport.drag({pressDrag: false})
            changeCursor('fill');
        }
    }

    exportDocument(title) {
        if (!this.undone) {
            this.app.renderer.render(this.renderTextureSpriteTemp, this.renderTexture, false, null, false);
            this.renderTextureSpriteTemp.destroy(true);
            this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
        }
        this.app.renderer.extract.canvas(this.renderTexture).toBlob(b => {
            const a = document.createElement('a');
            document.body.append(a);
            a.download = `${title}.png`;
            a.href = URL.createObjectURL(b);
            a.click();
            a.remove();
        }, "image/png");
    }

    async resizeImage(img, width, height, imgType, quality) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');
    
            canvas.width = width;
            canvas.height = height;
        
            ctx.drawImage(img, 0, 0, width, height);
    
            canvas.toBlob(blob => {
                resolve(blob);
            } ,imgType, quality);
        });
    }

    imageToDataUri(img, width, height, type, quality) {
        var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
    
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL(type, quality);
    }

    async saveDocument(doc, temp) {
        if (!this.undone) {
            this.app.renderer.render(this.renderTextureSpriteTemp, this.renderTexture, false, null, false);
            this.renderTextureSpriteTemp.destroy(true);
            this.setupRenderTexture(this.doc.width, this.doc.height, 'temp');
        }
        let canvas = this.app.renderer.plugins.extract.canvas(this.renderTexture, "image/png");
        let original = await this.resizeImage(canvas, this.doc.width, this.doc.height, 'image/png', 1);
        let quarter = await this.resizeImage(canvas, 580, 774, 'image/webp', 0.8); // this only works with tshirt designs
        let base64Img = ""
        if (!temp) {
            base64Img = this.imageToDataUri(canvas, this.doc.width, this.doc.height, 'image/png', 1);
        }

        try {
            this.doc = doc;
            let saveState = {
                uuid: uuidv4(),
                doc: this.doc,
                tol: this.tol,
                img: original,
                imgQuarter: quarter,
                imgBase64: base64Img,
                modified: Date.now(),
                uploaded: false
            }

            if (temp) {
                await set('temp-state', saveState);
            } else {
                let states = await get('states');
                let newArr = states.map((v,i) => {
                    if (i === doc.stateId) {return saveState}
                    else {return v}
                });
                await set(`states`, newArr);
            }
            return Promise.resolve('ok')
        } catch (err) {
            console.error(err)
            return Promise.reject(err);
        }
    }

    newDocument(doc) {
        this.doc = doc;
        this.app.stage.children[0].destroy();
        this.applyTemplate();
    }

    async openDocument(stateId) {
        try {
            let states = await get('states')
            let state = states[stateId]
            this.doc = state.doc;
            this.app.stage.children[0].destroy();
            this.applyTemplate();

            if (this.app.loader.resources.loadimage) {
                delete this.app.loader.resources.loadimage
            } 

            this.app.loader
            .add('loadimage', state.imgBase64)
            .load((loader, resources) => {
                const loadImageSprite = new PIXI.Sprite(resources.loadimage.texture);
                if (this.doc.template === "tshirt") {
                    loadImageSprite.width = this.doc.width / 2;
                    loadImageSprite.height = this.doc.height / 2;
                } else {
                    loadImageSprite.width = this.doc.width;
                    loadImageSprite.height = this.doc.height;
                }
                this.app.renderer.render(loadImageSprite, this.renderTexture, false, null, false);
                loadImageSprite.destroy();
            })
        } catch (err) {
            console.error(err);
        }
    }

    changeTshirtColor(color) {
        this.tshirtSprite.tint = this.parseTemplateColor(color);
    }

    applyFill() {
        let graphic = new PIXI.Graphics();
        graphic.beginFill(this.tol.fill.staticColor);

        if (this.doc.template === "tshirt") {
            graphic.drawRect(0, 0, this.doc.width / 2, this.doc.height / 2);
        } else {
            graphic.drawRect(0, 0, this.doc.width, this.doc.height);
        }
        graphic.endFill();
        graphic.alpha = this.tol.fill.alpha;
        this.app.renderer.render(graphic, this.renderTextureTemp, false, null, false);
        graphic.destroy();
    }

    spinDocument() { this.doc.spin = !this.doc.spin; }

    resetView() {
        this.viewport.snap(this.app.screen.width / 2, this.app.screen.height / 2, { removeOnComplete: true, time: 100 })
    }

    animate() {
        this.app.ticker.add((delta) => {
            if (this.doc.spin) {
                this.rotContainer.angle += 1 * delta;
            }
            for (let i = 0; i < this.brushes.length; i++) {
                let done = true;
                if (!this.brushes[i].stopped) {
                    done = false;
                    this.brushes[i].move();
                } else {
                    done = true;
                }
                if (done) {
                    let selFilters = []
                    if (this.brushes[i].opt.effects.filter1 !== 'none') {
                        selFilters.push(
                            filters[this.brushes[i].opt.effects.filter1](this.brushes[i].opt.birthPos, this.brushes[i].opt.size)
                        )
                    }
                    if (this.brushes[i].opt.effects.filter2 !== 'none') {
                        selFilters.push(
                            filters[this.brushes[i].opt.effects.filter2](this.brushes[i].opt.birthPos, this.brushes[i].opt.size)
                        )
                    }
                    if (selFilters.length > 0) { this.brushes[i].shapeContainer.filters = selFilters; }

                    if (this.brushes[i] instanceof EraserBrush) {
                        this.app.renderer.render(this.brushes[i].shapeContainer, this.renderTexture, false, null, false);

                        this.rotContainer.removeChild(this.renderTextureSpriteTemp);
                        this.undone = true;
                        this.eraserLastUsed = true;
                    } else {
                        this.app.renderer.render(this.brushes[i].shapeContainer, this.renderTextureTemp, false, null, false);
                        this.eraserLastUsed = false;
                    }

                    this.rotContainer.removeChild(this.brushes[i].shapeContainer); // remove from rotating container 
                    this.brushes[i].shapeContainer.destroy()
                    this.brushes.splice(i, 1); // remove brush 
                }
            }
        });
    }
}