import { Modal, Container, Header, Segment } from 'semantic-ui-react';

export const FAQModal = () => {
    const FAQPanel = (
        <Modal.Content scrolling>
            <Container text>
            <Segment vertical >
                <Header as="h4">What is with the name?</Header>
                <p>
                    Mancer is a suffix used to denote a type of wizard e.g. Pyromancer, Necromancer, etc.
                    Admittedly, Mancer is a fairly nerdy name, however it is short, easy to spell, and good for search results.
                </p>

                <Header as="h4">Only T-shirts?</Header>
                <p>
                    Yes, for now... in the future other item types will be offered. Canvas prints, sweatshirts, and totes coming soon.
                </p>

                <Header as="h4">Does summoning just mean ordering?</Header>
                <p>
                    Yes, but it sounds cooler and more magical.
                </p>

                <Header as="h4">I found a bug! What should I do?</Header>
                <p>
                    Please post it in the #needsimprovement channel on the Mancer Discord server.
                </p>

                <Header as="h4">I don't see my design on the Summon page</Header>
                <p>
                    Make sure to save your design first, then it should be available to summon. 
                </p>

                <Header as="h4">My saved design disappeared, what gives?</Header>
                <p>
                    Designs are saved using your browsers local storage, that storage for most part is quite stable however it can be cleared if you delete browser's cache,
                    or if your PC is running out of disk space. Of course if you share your design or order a tshirt you will always have a copy =)
                </p>

                <Header as="h4">Can I use the app for something other than designing T-shirts?</Header>
                <p>
                    Absolutely, select "Image Only" under Template when making a new design. You are welcome to export any of your designs and use how you see fit.
                </p>

                <Header as="h4">Why does sharing require that I license my design as CCO?</Header>
                <p>
                    Mostly for simplicity sake, other license types have restrictions that would require Mancer to implement a more complex logic for sharing. In the future, other license types may be allowed. 
                </p>
            </Segment>
            </Container>
        </Modal.Content>
    )
    return (
        <Modal
            trigger={<button className="yellowLink buttonAsLink">FAQ</button>}
            header="Frequently Asked Questions"
            content={FAQPanel}
            closeIcon
            actions={[{ key: 'done', content: 'Close', negative: true }]}
        />
    )
}