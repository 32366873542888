import { TopMenu } from './Components/TopMenu';
import { Container, Header, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Footer } from './Components/Footer';
import { useShoppingCart } from 'use-shopping-cart';
import { createMedia } from '@artsy/fresnel'


const { MediaContextProvider, Media } = createMedia({
	breakpoints: {
		mobile: 0,
		tablet: 768,
		computer: 1024,
	},
})

const SuccessHeading = () => (
    <Header as='h1'
        inverted
        content="🎉 Success 🎉"
        style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1.5em'
        }}
    />
)

const DesktopContainer = (props) => {
    const { children } = props
    return (
        <Media greaterThan='mobile'>
            <div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '22em' }}>
                <Segment
                    inverted
                    textAlign='center'
                    style={{ minHeight: 200, padding: '1em 0em', borderStyle: "none none solid none", borderColor: "#c7158580" }}
                    vertical
                >
                    <Container>
                        <TopMenu />
                        <SuccessHeading />
                    </Container>
                </Segment>
                { children }
            </div>
        </Media>
    )
}

const MobileContainer = (props) => {
    const { children } = props
    return (
        <Media at='mobile'>
            <div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '38em' }}>
                <Segment
                    inverted
                    textAlign='center'
                    style={{ minHeight: 200, padding: 0, borderStyle: "none none solid none", borderColor: "#c7158580" }}
                    vertical
                >
                    <Container>
                        <TopMenu mobile />
                        <SuccessHeading />
                    </Container>
                </Segment>
                { children }
            </div>
        </Media>
    )
}

const ResponsiveContainer = ({ children }) => (
	<MediaContextProvider>
		<DesktopContainer>{children}</DesktopContainer>
		<MobileContainer>{children}</MobileContainer>
	</MediaContextProvider>
)

MobileContainer.propTypes = {
	children: PropTypes.node,
}

DesktopContainer.propTypes = {
	children: PropTypes.node
}

ResponsiveContainer.propTypes = {
	children: PropTypes.node,
}

export const SuccessLayout = () => {
    const { clearCart } = useShoppingCart();
    clearCart();

    return (
        <ResponsiveContainer>
            <Container style={{margin: '2em'}}>
                <Header as="h1"
                    inverted
                    content="Sweet! Order confirmed. A reciept will be emailed to you shortly. (◕‿◕)♡"
                />
            </Container>
            <Footer />
        </ResponsiveContainer>
    )
}

